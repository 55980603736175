import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Box, LinearProgress, Typography } from '@material-ui/core';
import {
  getRewardCoinSummary,
} from "actions";
import * as H from "helper";

const useStyles = makeStyles((theme) =>
  createStyles({
    tooltip: {
      position: 'absolute',
      top: '8px',
      left: '50%',
      transform: 'translateX(-50%)',
      padding: '5px 10px',
      borderRadius: '4px',
      fontSize: '14px',
      whiteSpace: 'nowrap',
    },
    box: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      borderRight: "1px solid #dcdcdc",
    },
    boxLast: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(0),
    },
    title: {
      display: "block",
      height: 32,
      fontSize: 14,
    },
    value: {
      paddingTop: 0,
      paddingBottom: 0,
      minWidth: 150,
    },
  })
);

const RewardCoinSummary = ({
  rewardCoinSummaryData,
  getRewardCoinSummary,
  dateRangePicTree
}) => {
  const classes = useStyles();
  const [initialDataCall, setInitialDataCall] = useState(true);

  useEffect(() => {
    // getRewardCoinSummary();
  }, []);

  useEffect(() => {
    if (dateRangePicTree[0] !== null && dateRangePicTree[1]!== null
      && initialDataCall) {
      getRewardCoinSummary();
      setInitialDataCall(false);
    }
  }, [dateRangePicTree]);

  return (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <Grid container spacing={1}>
          <Grid item sm={6} md={4} lg={2}>
            <div className={classes.box}>
              <Grid container direction={"row"}>
                <Grid item xs={11}>
                  <Typography
                    variant="caption"
                    className={classes.title}
                  >支払い総額</Typography>
                </Grid>
                <Grid item xs={1} sm={6} md={4}>
                  <Typography variant="h6" className={classes.value}>
                    {rewardCoinSummaryData.amount !== undefined
                      ? `${H.FormatNumStrHelper.formatCommaThousand(rewardCoinSummaryData.amount)}`
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={6} md={4} lg={2}>
            <div className={classes.box}>
              <Grid container direction={"row"}>
                <Grid item xs={11}>
                  <Typography
                    variant="caption"
                    className={classes.title}
                  >支払い件数</Typography>
                </Grid>
                <Grid item xs={1} sm={6} md={4}>
                  <Typography variant="h6" className={classes.value}>
                    {rewardCoinSummaryData.count !== undefined
                      ? `${H.FormatNumStrHelper.formatCommaThousand(rewardCoinSummaryData.count)}`
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={6} md={4} lg={2}>
            <div className={classes.box}>
              <Grid container direction={"row"}>
                <Grid item xs={11}>
                  <Typography
                    variant="caption"
                    className={classes.title}
                  >支払いユニーク数</Typography>
                </Grid>
                <Grid item xs={1} sm={6} md={4}>
                  <Typography variant="h6" className={classes.value}>
                    {rewardCoinSummaryData.players !== undefined
                      ? `${H.FormatNumStrHelper.formatCommaThousand(rewardCoinSummaryData.players)}`
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={6} md={4} lg={2}>
            <div className={classes.box}>
              <Grid container direction={"row"}>
                <Grid item xs={11}>
                  <Typography
                    variant="caption"
                    className={classes.title}
                  >1件当たりの平均額</Typography>
                </Grid>
                <Grid item xs={1} sm={6} md={4}>
                  <Typography variant="h6" className={classes.value}>
                    {rewardCoinSummaryData.avgByCount !== undefined
                      ? `${H.FormatNumStrHelper.formatCommaThousand(rewardCoinSummaryData.avgByCount)}`
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={6} md={4} lg={2}>
            <div className={classes.box}>
              <Grid container direction={"row"}>
                <Grid item xs={11}>
                  <Typography
                    variant="caption"
                    className={classes.title}
                  >1人当たりの平均額</Typography>
                </Grid>
                <Grid item xs={1} sm={6} md={4}>
                  <Typography variant="h6" className={classes.value}>
                    {rewardCoinSummaryData.avgByPlayers !== undefined
                      ? `${H.FormatNumStrHelper.formatCommaThousand(rewardCoinSummaryData.avgByPlayers)}`
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={6} md={4} lg={2}>
            <div className={classes.box}>
              {/* <Grid container direction={"row"}>
                <Grid item xs={11}>
                  <Typography
                    variant="caption"
                    className={classes.title}
                  >初回達成の平均所要時間</Typography>
                </Grid>
                <Grid item xs={1} sm={6} md={4}>
                  <Typography variant="h6" className={classes.value}>
                    {rewardCoinSummaryData.avgMinutesTillFirstAchieve !== undefined
                      ? `${rewardCoinSummaryData.avgMinutesTillFirstAchieve}分`
                      : "-"}
                  </Typography>
                </Grid>
              </Grid> */}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

RewardCoinSummary.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  rewardCoinSummaryData: state.pictree.rewardCoinSummaryData,
  dateRangePicTree: state.page.dateRangePicTree,
});

const mapDispatchToProps = (dispatch) => ({
  getRewardCoinSummary: (param) => dispatch(getRewardCoinSummary(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(RewardCoinSummary));
