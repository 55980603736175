import { combineReducers } from 'redux';

import affiliateReducer from 'reducers/affiliateReducer';
import campaignReducer from 'reducers/campaignReducer';
import usersReducer from 'reducers/usersReducer';
import activeUsersReducer from 'reducers/activeUsersReducer';
import mapReducer from 'reducers/mapReducer';
import pageReducer from 'reducers/pageReducer';
import homeReducer from 'reducers/homeReducer';
import puzzleReducer from 'reducers/puzzleReducer';
import puzzleGeoReducer from 'reducers/puzzleGeoReducer';
import cohortReducer from 'reducers/cohortReducer';
import nftDepReducer from 'reducers/nftDepReducer';
import nftDepOverviewReducer from 'reducers/nftdep/overviewReducer';
import nftDepTrendReducer from 'reducers/nftdep/trendReducer';
import nftOwnTrendReducer from 'reducers/nftdep/nftOwnTrendReducer';
import nftTxHistoryReducer from 'reducers/nftdep/nftTxHistoryReducer';
import nftHoldersReducer from 'reducers/nftdep/nftHoldersReducer';
import stakingReducer from 'reducers/nftdep/stakingReducer';
import jtcbNftTrendReducer from 'reducers/jtcb/jtcbNftTrendReducer';
import jtcbScholarReducer from 'reducers/jtcb/scholarReducer';
import jtcbScholarGuildReducer from 'reducers/jtcb/scholarGuildReducer';
import jtcbScholarTrendReducer from 'reducers/jtcb/scholarTrendReducer';
import jtcbScholarAmuletReducer from 'reducers/jtcb/scholarAmuletReducer';
import dataReducer from 'reducers/data/dataReducer';
import kpiReducer from 'reducers/kpi/kpiReducer';
import cookinburgerNftTrendReducer from 'reducers/cookinburger/cookinburgerNftTrendReducer';
import cookinBurgerScholarReducer from 'reducers/cookinburger/cookinBurgerScholarReducer';
import dragonRamenNftTrendReducer from 'reducers/dragonramen/dragonramenNftTrendReducer';
import graffitiRacerNftTrendReducer from 'reducers/graffitiracer/graffitiracerNftTrendReducer';
import kamuiverseNftTrendReducer from 'reducers/kamuiverse/kamuiverseNftTrendReducer';
import luckyFarmerNftTrendReducer from 'reducers/luckyfarmer/luckyFarmerNftTrendReducer';
import luckyFarmerGamePaidTrendReducer from 'reducers/luckyfarmer/luckyFarmerGamePaidTrendReducer';
import graffitiracerGamePaidTrendReducer from 'reducers/graffitiracer/graffitiracerGamePaidTrendReducer';
import cookinBurgerGamePaidTrendReducer from 'reducers/cookinburger/cookinBurgerGamePaidTrendReducer';
import walletReducer from 'reducers/nftdep/walletReducer';
import lostArchiveNftTrendReducer from 'reducers/lostarchive/lostArchiveNftTrendReducer';
import soulFusersNftTrendReducer from 'reducers/soulfusers/soulFusersNftTrendReducer';
import gameNftTrendReducer from 'reducers/data/gameNftTrendReducer';
import inGameItemSalesOverviewReducer from 'reducers/data/inGameItemSalesOverviewReducer';
import inGameItemSalesTrendReducer from 'reducers/data/inGameItemSalesTrendReducer';
import inGameItemSalesHistoryReducer from 'reducers/data/inGameItemSalesHistoryReducer';
import nftSalesReducer from 'reducers/data/nftSalesReducer';
import pictreeReducer from 'reducers/pictree/pictreeReducer';
import pictreeNttReducer from 'reducers/pictree/pictreeNttReducer';
import a2eReducer from 'reducers/a2e/a2eReducer';

const rootReducer = combineReducers({
    affiliate: affiliateReducer,
    campaign: campaignReducer,
    users: usersReducer,
    activeUsers: activeUsersReducer,
    map: mapReducer,
    page: pageReducer,
    home: homeReducer,
    puzzle: puzzleReducer,
    puzzleGeo: puzzleGeoReducer,
    cohort: cohortReducer,
    nftDep: nftDepReducer,
    nftDepOverview: nftDepOverviewReducer,
    nftDepTrend: nftDepTrendReducer,
    nftOwnTrend: nftOwnTrendReducer,
    nftDepTxHisotry: nftTxHistoryReducer,
    nftHolders: nftHoldersReducer,
    nftSales: nftSalesReducer,
    staking: stakingReducer,
    jtcbNftTrend: jtcbNftTrendReducer,
    jtcbScholar: jtcbScholarReducer,
    jtcbScholarGuild: jtcbScholarGuildReducer,
    jtcbScholarTrend: jtcbScholarTrendReducer,
    jtcbScholarAmulet: jtcbScholarAmuletReducer,
    data: dataReducer,
    kpi: kpiReducer,
    cookinBurgerNftTrend: cookinburgerNftTrendReducer,
    cookinBurgerScholar: cookinBurgerScholarReducer,
    dragonRamenNftTrend: dragonRamenNftTrendReducer,
    graffitiRacerNftTrend: graffitiRacerNftTrendReducer,
    kamuiVerseNftTrend: kamuiverseNftTrendReducer,
    luckyFarmerNftTrend: luckyFarmerNftTrendReducer,
    luckyFarmerGamePaidTrend: luckyFarmerGamePaidTrendReducer,
    graffitiRacerGamePaidTrend: graffitiracerGamePaidTrendReducer,
    cookinBurgerGamePaidTrend: cookinBurgerGamePaidTrendReducer,
    wallet: walletReducer,
    lostArchiveNftTrend:lostArchiveNftTrendReducer,
    soulfusers: soulFusersNftTrendReducer,
    gameNftTrend: gameNftTrendReducer,
    inGameItemSales: inGameItemSalesOverviewReducer,
    inGameItemSalesTrend: inGameItemSalesTrendReducer,
    inGameItemSalesHistory: inGameItemSalesHistoryReducer,
    pictree: pictreeReducer,
    pictreeNtt: pictreeNttReducer,
    a2e: a2eReducer,
});

export default rootReducer;
