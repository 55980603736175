import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Breadcrumbs, Grid, Typography, Chip, Card, CardContent } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import OverviewStyles from "pages/NftDep/Auction/OverviewStyles";
import {
  setCurrentPage,
  getPointOverviewDataPicTree,
  getPointTableDataPicTree,
} from "actions";
import * as C from "components";
import RewardCoinSummary from "components/PicTree/Kakin/RewardCoinSummary";
import RewardCoinTable from "components/PicTree/Kakin/RewardCoinTable";
import RewardCoinRanking from "components/PicTree/Kakin/RewardCoinRanking";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    position: "-webkit-sticky",
    position: "sticky",
    top: 0,
    zIndex: 1000,
  },
  fillRemailSpace: {
    flex: "auto",
  },
  sectionTop: {
    marginTop: -16,
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
    },
  },
  breadcrumbs: {
    marginTop: 16,
    minHeight: 30,
  },
}));

const PicTreeRewardCoin = ({
  setCurrentPage,
  getPointOverviewDataPicTree,
  getPointTableDataPicTree,
  pageTopLoader,
}) => {
  const classes = useStyles();

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    // getPointOverviewDataPicTree();
  }, []);

  return (
    <C.ContentWrapper>
      <Grid
        container
        direction="row"
        className={classes.sectionTop}
        justifyContent="space-between"
      >
        <Grid item>
          <Breadcrumbs separator="›" className={classes.breadcrumbs}>
            <Typography>PicTrée</Typography>
            <Typography variant="body1">課金</Typography>
            <Typography variant="body1">報酬コイン</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item className={classes.fillRemailSpace}></Grid>
        <Grid item>
          <C.CalendarRangePicker
            mDate={"2024/04/13"}
            page="pictree/sales/reward-coin"
            pictree
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" spacing={0} style={{ marginTop: 0 }}>
        <Grid item xs={12} md={12} lg={11}>
          <Card>
            <CardContent>
              <div className={classes.cardContentSection}>
                <RewardCoinSummary />
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        style={{ marginTop: 16, marginRight: 16, marginBottom: 8 }}
        justifyContent="center"
        alignItems="center"
      >
        {/* <Grid item xs={12} md={6} lg={6} style={{ marginTop: 0, marginLeft: 0 }}>
          <RewardCoinTable />
        </Grid> */}
        <Grid item xs={12} md={12} lg={11} style={{ marginTop: 0, justifyContent: "center" }}>
          <RewardCoinRanking />
        </Grid>
      </Grid>
    </C.ContentWrapper>
  );
};

PicTreeRewardCoin.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  loading: state.nftDep.loading,
  pageTopLoader: state.page.pageTopLoader,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getPointOverviewDataPicTree: () => dispatch(getPointOverviewDataPicTree()),
  getPointTableDataPicTree: () => dispatch(getPointTableDataPicTree()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(OverviewStyles, { withTheme: true })(PicTreeRewardCoin));
