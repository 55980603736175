import React, { Fragment, useState, useEffect, useRef } from "react";
import ReactMapGL, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Source,
  Layer,
} from "react-map-gl";
import { Chip } from "@material-ui/core";
import Pin from "./Pin";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoieWFtYXNuYXgiLCJhIjoiY2x1YmFuaWVnMHVnMDJzbGQwdzNqYjk5biJ9.Oie3_d5us7a0b1e5abksgg"; //process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
// pk.eyJ1IjoieWFtYXNuYXgiLCJhIjoiY2x1YmFuaWVnMHVnMDJzbGQwdzNqYjk5biJ9.Oie3_d5us7a0b1e5abksgg // KpiDash-Dev
// pk.eyJ1IjoicGVsbW9yZXhwZHNnaXMiLCJhIjoiY2swY3Q4anhkMDB1YzNjcWVzdWpxM3JqMyJ9.dXzNM9VAn2gpqRphAGoLTw
// pk.eyJ1IjoiZGVhLXl5YW1hZ3VjaGkiLCJhIjoiY2x1YWo2Y3dyMG5hdjJwdDU2ODA4MHNoYiJ9.q60AWM7wayOHd-LFxbDzDg
// pk.eyJ1IjoiZGVhLXl5YW1hZ3VjaGkiLCJhIjoiY2x1YWpieTRjMGxkYzJrazRrdDZobDJtdyJ9.t4Yp-iU5neijrsiope7Onw // KpiDash

function PhotoShootMap({ records, type, width, height }) {
  const [popupInfo, setPopupInfo] = useState(null);
  const [mapStyle, setMapStyle] = useState("mapbox://styles/yamasnax/clubgryio000s01p98ef99wcb");
  const [terrainEnabled, setTerrainEnabled] = useState(false);

  const calculateBounds = (records) => {
    const bounds = records.reduce(
      (acc, curr) => {
        return {
          minLng: Math.min(acc.minLng, parseFloat(curr.longitude)),
          minLat: Math.min(acc.minLat, parseFloat(curr.latitude)),
          maxLng: Math.max(acc.maxLng, parseFloat(curr.longitude)),
          maxLat: Math.max(acc.maxLat, parseFloat(curr.latitude)),
        };
      },
      {
        minLng: Infinity,
        minLat: Infinity,
        maxLng: -Infinity,
        maxLat: -Infinity,
      }
    );

    const paddingPercentage = 0.1;
    const lngPadding = (bounds.maxLng - bounds.minLng) * paddingPercentage;
    const latPadding = (bounds.maxLat - bounds.minLat) * paddingPercentage;

    return {
      minLng: bounds.minLng - lngPadding,
      minLat: bounds.minLat - latPadding,
      maxLng: bounds.maxLng + lngPadding,
      maxLat: bounds.maxLat + latPadding,
    };
  };

  const calculateCenterAndZoom = (bounds) => {
    const center = [
      (bounds.minLng + bounds.maxLng) / 2,
      (bounds.minLat + bounds.maxLat) / 2,
    ];
    const zoom = Math.min(
      Math.log2(960 / (bounds.maxLng - bounds.minLng)),
      Math.log2(480 / (bounds.maxLat - bounds.minLat))
    );
    return { center, zoom };
  };

  const boundsWithPadding = records.reduce(
    (acc, curr) => {
      return {
        minLng: Math.min(acc.minLng, parseFloat(curr.longitude)),
        minLat: Math.min(acc.minLat, parseFloat(curr.latitude)),
        maxLng: Math.max(acc.maxLng, parseFloat(curr.longitude)),
        maxLat: Math.max(acc.maxLat, parseFloat(curr.latitude)),
      };
    },
    {
      minLng: Infinity,
      minLat: Infinity,
      maxLng: -Infinity,
      maxLat: -Infinity,
    }
  );
  const paddingPercentage = 0.1;

  const lngPadding =
    (boundsWithPadding.maxLng - boundsWithPadding.minLng) * paddingPercentage;
  const latPadding =
    (boundsWithPadding.maxLat - boundsWithPadding.minLat) * paddingPercentage;
  const bounds = {
    minLng: boundsWithPadding.minLng - lngPadding,
    minLat: boundsWithPadding.minLat - latPadding,
    maxLng: boundsWithPadding.maxLng + lngPadding,
    maxLat: boundsWithPadding.maxLat + latPadding,
  };

  const center = [
    (bounds.minLng + bounds.maxLng) / 2,
    (bounds.minLat + bounds.maxLat) / 2,
  ];
  const zoom = Math.min(
    Math.log2(960 / (bounds.maxLng - bounds.minLng)),
    Math.log2(480 / (bounds.maxLat - bounds.minLat))
  );

  const [viewport, setViewport] = React.useState({
    latitude: center[1], //avgLatitude, // Maebashi 36.393351
    longitude: center[0], //avgLongitude, // Maebashi 139.064853
    zoom: zoom,
    bearing: terrainEnabled ? 80 : 0,
    pitch: terrainEnabled ? 80 : 0,
  });

  useEffect(() => {
    if (records.length > 0) {
      const bounds = calculateBounds(records);
      const { center, zoom } = calculateCenterAndZoom(bounds);
      setViewport({
        latitude: center[1],
        longitude: center[0],
        zoom: zoom,
        bearing: terrainEnabled ? 80 : 0,
        pitch: terrainEnabled ? 80 : 0,
      });
    }
  }, [records, terrainEnabled]);

  const toggleMapStyle = () => {
    setMapStyle((prev) =>
      prev === "mapbox://styles/yamasnax/clubgryio000s01p98ef99wcb"
        ? "mapbox://styles/mapbox/satellite-v9"
        : "mapbox://styles/yamasnax/clubgryio000s01p98ef99wcb"
    );
  };

  const toggleTerrain = () => {
    setTerrainEnabled(!terrainEnabled);
  };

  return (
    <div style={{ width: width, height: height }}>
      <ReactMapGL
        mapboxAccessToken={MAPBOX_TOKEN}
        style={{ width: "100%", height: "90%" }}
        //mapStyle="mapbox://styles/mapbox/streets-v8" // mapbox://styles/mapbox/streets-v11 // mapbox://styles/mapbox/satellite-v9
        mapStyle={mapStyle}
        {...viewport}
        onMove={(evt) => setViewport(evt.viewState)}
        terrain={
          terrainEnabled ? { source: "mapbox-dem", exaggeration: 1.5 } : null
        }
      >
        <FullscreenControl />
        <NavigationControl />

        {records.map((record, index) => (
          <Marker
            key={index}
            latitude={parseFloat(record.latitude)}
            longitude={parseFloat(record.longitude)}
            onClick={(e) => {
              // If we let the click event propagates to the map, it will immediately close the popup
              // with `closeOnClick: true`
              e.originalEvent.stopPropagation();
              setPopupInfo(record);
            }}
            offsetLeft={-20}
            offsetTop={-10}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: "red",
              }}
            />
          </Marker>
        ))}

        <div style={{ position: "absolute", top: 10, left: 10, zIndex: 1 }}>
          <Chip
            size="small"
            onClick={toggleMapStyle}
            label={
              mapStyle.indexOf("streets-v8") !== -1 ? "航空写真表示" : "デフォルト表示"
            }
            style={{ backgroundColor: "#4caf50", color: "#fff" }}
          />
          <Chip
            size="small"
            onClick={toggleTerrain}
            label={terrainEnabled ? "2D表示" : "3D表示"}
            style={{ backgroundColor: "#4caf50", color: "#fff" }}
          />
        </div>

        {popupInfo && type == "checkin" && (
          <Popup
            anchor="top"
            longitude={Number(popupInfo.longitude)}
            latitude={Number(popupInfo.latitude)}
            onClose={() => setPopupInfo(null)}
            style={{ width: 600 }}
            className="custom-popup"
          >
            <div>
              アセットID: {popupInfo.asset_id}
              <br />
              ユーザーID: {popupInfo.user}
              <br />
              撮影日: {popupInfo.dateTime}
            </div>
          </Popup>
        )}

        {popupInfo && type == "photoshoot" && (
          <Popup
            anchor="top"
            longitude={Number(popupInfo.longitude)}
            latitude={Number(popupInfo.latitude)}
            onClose={() => setPopupInfo(null)}
            style={{ width: 600 }}
            className="custom-popup"
          >
            <div>
              アセットID: {popupInfo.asset_id}
              <br />
              写真数: {popupInfo.picCount}
              <br />
              撮影部位: {popupInfo.parts}
              <br />
              ユーザーID: {popupInfo.user}
              <br />
              撮影日: {popupInfo.dateTime}
              {/* <a
                target="_new"
                href={`http://en.wikipedia.org/w/index.php?title=Special:Search&search=${popupInfo.city}, ${popupInfo.state}`}
              >
                Wikipedia
              </a> */}
            </div>
            {/* <img width="100%" src={popupInfo.image} /> */}
          </Popup>
        )}
        {terrainEnabled && (
          <>
            <Source
              id="mapbox-dem"
              type="raster-dem"
              url="mapbox://mapbox.mapbox-terrain-dem-v1"
              tileSize={512}
              maxzoom={14}
            />
            <Layer
              id="terrain-data"
              type="hillshade"
              source="mapbox-dem"
              paint={{
                "hillshade-exaggeration": 0.5,
              }}
            />
          </>
        )}
      </ReactMapGL>
    </div>
  );
}

export default PhotoShootMap;
