import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import RoutesAuth from "RoutesAuth";
import { Provider } from "react-redux";
import configureStore from "store";
import "App.css";

const store = configureStore();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isAuthenticated: true });
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          this.userHasAuthenticated(true);
          localStorage.setItem("idToken", session.idToken.jwtToken);
        }
      );
    } catch (e) {
      this.userHasAuthenticated(false);
      console.log("Unable to refresh Token:", e);
    }
    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  handleLogout = async (event) => {
    await Auth.signOut();
    window.localStorage.clear();
    this.userHasAuthenticated(false);
    this.props.history.push("/signin");
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      handleLogout: this.handleLogout,
    };
    return (
      <Provider store={store}>
        <div className="">
          <RoutesAuth
            childProps={childProps}
            isPicTree={
              localStorage.getItem("access_key") ===
              "MHoNNpheuCs67M80p6IB7MFCe9i8"
                ? true
                : false
            }
            isNtt={
              localStorage.getItem("access_key") ===
              "BDvE9Hd11ZKaHjuPWfnzIGQrvq1d"
                ? true
                : false
            }
          />
        </div>
      </Provider>
    );
  }
}

export default withRouter(App);
