// Icons Material can be defined here, not fontAwesome

import {
  PicTreeReviewSummaryNtt,
  PicTreeCheckInNtt,
  PicTreeMapNtt,
  // PicTreeReviewAppearance,
} from "pages";

export default {
  items: [
    {
      path: "/game/pictree/ntt/photos/overview",
      name: "概要",
      type: "link",
      icon: false,
      category: "撮影",
      component: PicTreeReviewSummaryNtt,
      sideBar: true,
      divider: false,
    },
    {
      path: "/game/pictree/ntt/photos/map",
      name: "地図表示",
      type: "link",
      icon: false,
      component: PicTreeMapNtt,
      sideBar: true,
      divider: false,
    },
    // {
    //   path: "/game/pictree/ntt/photos/denchu/appearance",
    //   name: "出現回数",
    //   type: "link",
    //   icon: false,
    //   component: PicTreeReviewAppearance,
    //   sideBar: true,
    //   divider: false,
    // },
    {
      path: "/game/pictree/ntt/checkin",
      name: "チェックイン",
      type: "link",
      icon: false,
      component: PicTreeCheckInNtt,
      sideBar: true,
      divider: false,
    },
  ],
};
