import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import MUIDataTable from "mui-datatables";

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      borderRight: "1px solid #dcdcdc",
    },
    tableHead: {
      height: 48,
      backgroundColor: "#fafafa",
    },
    tableRow: {
      height: 32,
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    tableRowNone: {
      height: 126,
    },
    tblHead: {
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 500,
    },
    tblHeadStatus: {
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 500,
    },
    tblHeadLeft: {
      textAlign: 'left',
      fontSize: 12,
      fontWeight: 500,
    },
    tblDataCenter: {
      textAlign: 'center',
    },
    tblDataLeft: {
      textAlign: 'left',
    },
    customTitle: {
      fontSize: 16,
      color: "#000",
    },
  })
);

const MissionTable = ({
  missionTableData,
  missionTableDataMui,
}) => {
  const classes = useStyles();
  const [countdowns, setCountdowns] = useState({}); // Store active countdowns per row
  const [rowStyles, setRowStyles] = useState({}); // Track row styles
  const [loading, setLoading] = useState(!missionTableData ? true : false);
  const [simplifiedStatuses, setSimplifiedStatuses] = useState({});

  useEffect(() => {
    if (!missionTableData || !missionTableDataMui) {
      return;
    }
    setLoading(false);
    const timer = setInterval(() => {
      const newCountdowns = {};
      const newRowStyles = {};
      const newSimplifiedStatuses = {}; // To store "開催中" or "終了"

      missionTableDataMui.forEach((row, index) => {
        const mission = missionTableData[index];
        const startTime = moment.tz(mission.occurredDatetimeJst, "YYYY-MM-DD HH:mm", "Asia/Tokyo");
        const endTime = moment.tz(mission.endDatetimeJst, "YYYY-MM-DD HH:mm", "Asia/Tokyo");
        const currentTime = moment.tz("Asia/Tokyo");

        if (currentTime.isBetween(startTime, endTime)) {
          const countdownSeconds = Math.max(0, endTime.diff(currentTime, "seconds"));
          const hours = Math.floor(countdownSeconds / 3600);
          const minutes = Math.floor((countdownSeconds % 3600) / 60);
          const seconds = countdownSeconds % 60;

          newCountdowns[index] = hours > 0
            ? `開催中<br />残り${hours}時間 ${minutes}分${seconds}秒`
            : `開催中<br />残り${minutes}分${seconds}秒`;
          newSimplifiedStatuses[index] = "開催中";
        } else {
          newCountdowns[index] = "終了";
          newSimplifiedStatuses[index] = "終了";
        }

        // Row Highlighting Logic
        const remainingMinutes = Math.max(0, endTime.diff(currentTime, "minutes"));
        const achievedNum = mission.achieverCount;

        if (remainingMinutes < 60 && remainingMinutes > 0 && achievedNum === 0) {
          newRowStyles[index] = "flashing";
        } else if (newCountdowns[index] === "終了" && achievedNum === 0) {
          newRowStyles[index] = "tableRowError";
        } else {
          newRowStyles[index] = "tableRow";
        }
      });

      setCountdowns(newCountdowns);
      setRowStyles(newRowStyles);
      setSimplifiedStatuses(newSimplifiedStatuses);
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, [missionTableData, missionTableDataMui]);

  const columns = [
    {
      name: "#",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
    {
      name: "ミッション名",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: (columnMeta) => ( <span style={{minWidth: 120}} className={classes.tblHeadLeft} >ミッション名</span> ),
        customBodyRender: (value) => {
          return <div style={{ minWidth: "200px" }} className={classes.tblDataLeft}>{value}</div>;
        },
      },
    },
    {
      name: "ステータス",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: () => (
          <span className={classes.tblHeadStatus}>ステータス</span>
        ),
        customBodyRender: (_, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const status = countdowns[rowIndex] || "計算中...";

          return (
            <div
              className={classes.tblDataCenter}
              style={{ minWidth: "100px" }}
              dangerouslySetInnerHTML={{ __html: status }}
            />
          );
        },
        filterOptions: {
          names: ["開催中", "終了"],
          logic(value, filterVal) {
            const derivedStatus = value === true ? "開催中" : "終了";
            return !filterVal.includes(derivedStatus);
          },
        },
      },
    },
    {
      name: "開始終了日時",
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: (columnMeta) => ( <span className={classes.tblHead} >開始終了<br />日時</span> ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
    {
      name: "時間",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => ( <span className={classes.tblHead} >時間</span> ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
    {
      name: "初回達成所要(分)",
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: (columnMeta) => ( <span className={classes.tblHead} >初回達成所要(分)</span> ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
    {
      name: "ミッション達成人数",
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: (columnMeta) => ( <span className={classes.tblHead} >達成者数</span> ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
    {
      name: "ミッション参加者数",
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: (columnMeta) => ( <span className={classes.tblHead} >参加者数</span> ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
    {
      name: "撮影枚数",
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: (columnMeta) => ( <span className={classes.tblHead} >撮影枚数</span> ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
    {
      name: "撮影基数",
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: (columnMeta) => ( <span className={classes.tblHead} >撮影基数</span> ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
  ];
  const options = {
    selectableRows: "none",
    filterType: "checkbox",
    filter: true,
    pagination: true,
    search: true,
    print: false,
    download: true,
    viewColumns: false,
    downloadOptions: {
      filename: "開催ミッションデータ",
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
    rowsPerPage: 20,
    textLabels: {
      body: {
        noMatch: loading ? "データ取得中です。" : "データがありません。",
        toolTip: "",
      },
      toolbar: {
        search: "検索",
        viewColumns: "列の表示",
        downloadCsv: "CSVダウンロード",
        filterTable: "テーブルのフィルター",
      },
      viewColumns: {
        title: "表示する列",
        titleAria: "テーブルの列の表示/非表示",
      },
      pagination: {
        next: "次のページ",
        previous: "前のページ",
        rowsPerPage: "表示件数",
      },
      filter: {
        all: "全て",
        title: "フィルター",
        reset: "リセット",
      },
      selectedRows: {
        text: "レコードを選択",
        delete: "削除",
        deleteAria: "選択したレコードを削除する",
      },
    },
    rowsPerPageOptions: [20, 50, 100],
    responsive: "standard",
    setRowProps: (row, rowIndex) => ({
      className: rowStyles[rowIndex] || "tableRow",
    }),
  };

  return (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <MUIDataTable
          title={<span className={classes.customTitle}>開催ミッション一覧</span>}
          data={missionTableDataMui}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
};

MissionTable.propTypes = {
  classes: PropTypes.object,
  missionTableData: PropTypes.array.isRequired,
  missionTableDataMui: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  missionTableData: state.pictree.missionSummaryData.missionTableData,
  missionTableDataMui: state.pictree.missionSummaryData.missionTableDataMui,
});

const mapDispatchToProps = (dispatch) => ({
  //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(MissionTable));
