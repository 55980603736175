import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Breadcrumbs, Grid, Typography, Button } from "@material-ui/core";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import HomeStyles from "pages/HomeStyles";
import {
  setCurrentPage,
  getRealTimeMapData,
} from "actions";
import * as C from "components";
import * as Var from "styles/variables";
import moment from "moment-timezone";
import RealTimeMap from "components/PicTree/Game/Map/RealTimeMap";
import SingleSelect from "components/Select/SingleSelect";

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    breadcrumbs: {
      marginTop: 16,
      minHeight: 30,
    },
    dataBtn: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      height: theme.spacing(4),
      color: Var.btnLabelColor,
    },
  })
);

const PicTreeMapRealTime = ({
  setCurrentPage,
  pageTopLoader,
  realTimeMapData,
  getRealTimeMapData,
}) => {
  const classes = useStyles();
  const [numOfUser, setNumberOfUsers] = useState(0);
  const [reatimeParams, setReatimeParams] = useState({
    range: 30,
    type: "denchu",
    property: "tepco",
  });
  const [jstRange, setJSTRange] = useState("");

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    const currentJST = moment().tz("Asia/Tokyo");
    const fromJST = currentJST.clone().subtract(reatimeParams.range, "minutes");
    const fromTime = fromJST.format("YYYY-MM-DD HH:mm");
    const toTime = currentJST.format("YYYY-MM-DD HH:mm");
    setJSTRange(`${fromTime} ~ ${toTime}`);
  }, []);

  useEffect(() => {
    const currentJST = moment().tz("Asia/Tokyo");
    const fromJST = currentJST.clone().subtract(reatimeParams.range, "minutes");
    const fromTime = fromJST.format("YYYY-MM-DD HH:mm");
    const toTime = currentJST.format("YYYY-MM-DD HH:mm");
    setJSTRange(`${fromTime} ~ ${toTime}`);
  }, [reatimeParams]);

  useEffect(() => {
    if (realTimeMapData[0].length > 0) {
      const uniqueUsers = _.uniqBy(realTimeMapData[0], "user");
      const uniqueUserCount = uniqueUsers.length;
      setNumberOfUsers(uniqueUserCount);
    } else {
      setNumberOfUsers(0);
    }
  }, [realTimeMapData]);

  const handleChangeRange = (event) => {
    const coppyParams = {
      range: Number(event.target.value),
      type: reatimeParams.type,
      property: "tepco",
    }
    setReatimeParams(coppyParams);
  };

  const handleChangeType = (event) => {
    const coppyParams = {
      range: reatimeParams.range,
      type: event.target.value,
      property: "tepco",
    }
    setReatimeParams(coppyParams);
  };

  const fetchData = () => {
    getRealTimeMapData(reatimeParams);
  };
  // console.log("LOG realTimeMapData:", realTimeMapData);

  return (
    <Fragment>
      <C.ContentWrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Breadcrumbs separator="›" className={classes.breadcrumbs}>
              <Typography>PicTrée</Typography>
              <Typography variant="body1">撮影</Typography>
              <Typography variant="body1">通常モード</Typography>
              <Typography variant="body1">リアルタイム</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={classes.fillRemailSpace}></Grid>
          <Grid item>
            <Typography variant="subtitle1" style={{marginTop: 16, marginRight: 16}}>
              {jstRange}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: 16, marginRight: 8, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="subtitle1" style={{marginTop: 16, marginRight: 16}}>
              撮影数： {realTimeMapData[0].length > 0 ? realTimeMapData[0].length : 0} | 撮影者数: {numOfUser}
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={0}
              direction="row"
              style={{ marginRight: 8, marginBottom: 8 }}
              justifyContent="flex"
            >
              <Grid item style={{ marginTop: 0, marginLeft: 8 }}>
                <SingleSelect
                  selected={reatimeParams.range}
                  options={[
                    { name: `過去30分`, value: 30 },
                    { name: `過去1時間`, value: 60 },
                    { name: `過去3時間`, value: 180 },
                    { name: `過去6時間`, value: 240 },
                    { name: `過去12時間`, value: 770 },
                    { name: `過去72時間`, value: 4320 },
                  ]}
                  handleChange={handleChangeRange}
                />
              </Grid>
              <Grid item style={{ marginLeft: 8 }}>
                <SingleSelect
                  selected={reatimeParams.type}
                  options={[
                    { name: `電柱撮影`, value: "denchu" },
                    { name: `換気口撮影`, value: "kankiko" },
                    { name: `地上設備撮影`, value: "groundEquipiment" },
                    // { name: `チェックイン`, value: "checkin" },
                  ]}
                  handleChange={handleChangeType}
                />
              </Grid>
              <Grid item>
                <Button
                  className={classes.dataBtn}
                  variant="contained"
                  color="primary"
                  onClick={() => fetchData()}
                >
                  GO
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <RealTimeMap
          records={realTimeMapData}
          type="photoshoot"
          width="100%"
          height="85vh"
          property="tepco"
          defaultCoord={[35.6649095, 139.759895, 12.27888490043715]}
        />
      </C.ContentWrapper>
    </Fragment>
  );
};

PicTreeMapRealTime.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  realTimeMapData: state.pictree.realTimeMapData,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getRealTimeMapData: (param) => dispatch(getRealTimeMapData(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(HomeStyles, { withTheme: true })(PicTreeMapRealTime));
