import React, { useState } from "react";
import ReactMapGL, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  Source,
  Layer,
} from "react-map-gl";
import { Chip } from "@material-ui/core";
import chroma from "chroma-js";

const MAPBOX_TOKEN = "pk.eyJ1IjoieWFtYXNuYXgiLCJhIjoiY2x1YmFuaWVnMHVnMDJzbGQwdzNqYjk5biJ9.Oie3_d5us7a0b1e5abksgg";

function RealTimeMap({ records, type, width, height, property, defaultCoord }) {
  const [mapStyle, setMapStyle] = useState("mapbox://styles/yamasnax/clubgryio000s01p98ef99wcb");
  const [viewport, setViewport] = useState({
    latitude: defaultCoord[0],
    longitude: defaultCoord[1],
    zoom: defaultCoord[2],
    bearing: 0,
    pitch: 0,
  });
  const [showClusters, setShowClusters] = useState(true);
  const [showUser, setShowUser] = useState(true);
  const [showUntaken, setUntaken] = useState(true);
  const [popupInfo, setPopupInfo] = useState(null);

  const generateColorForUser = (userId) => {
    // ユーザーIDのハッシュ値を計算
    const hash = Array.from(userId).reduce(
      (acc, char) => acc + char.charCodeAt(0),
      0
    );
    // ハッシュ値に基づいて色を決定（chromaを使ってHSL色相を生成）
    return chroma.hsl((hash % 360), 0.7, 0.6).hex();
  };

  // Create GeoJSON for the first dataset (array index 0)
  const firstDatasetGeoJSON = {
    type: "FeatureCollection",
    features: records[0].map((record) => ({
      type: "Feature",
      properties: {
        cluster: false,
        ...record,
        markerColor: generateColorForUser(record.user),
      },
      geometry: {
        type: "Point",
        coordinates: [parseFloat(record.longitude), parseFloat(record.latitude)],
      },
    })),
  };

  // Create GeoJSON for the second dataset (array index 1)
  const secondDatasetGeoJSON = {
    type: "FeatureCollection",
    features: records[1].map((record) => ({
      type: "Feature",
      properties: {
        ...record,
      },
      geometry: {
        type: "Point",
        coordinates: [parseFloat(record.longitude), parseFloat(record.latitude)],
      },
    })),
  };

  const clusterLayer = {
    id: "clusters",
    type: "circle",
    source: "clusters",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": "#51bbd6",
      "circle-radius": [
        "step",
        ["get", "point_count"],
        20,
        100,
        30,
        750,
        40,
      ],
    },
  };

  const clusterCountLayer = {
    id: "cluster-count",
    type: "symbol",
    source: "clusters",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 12,
    },
  };

  const unclusteredPointLayer = {
    id: "unclustered-point",
    type: "circle",
    source: "clusters",
    filter: ["!", ["has", "point_count"]],
    paint: {
      //"circle-color": "#11b4da",
      "circle-color": ["get", "markerColor"], // プロパティからカラーを取得
      "circle-radius": 8,
      "circle-stroke-width": 1,
      "circle-stroke-color": "#fff",
    },
  };

  // Heatmap layer for the second dataset
  const heatmapLayer = {
    id: "heatmap-layer",
    type: "heatmap",
    source: "secondDataset",
    paint: {
      // Increase the heatmap weight based on a property
      "heatmap-weight": [
        "interpolate",
        ["linear"],
        ["get", "latitude"],
        0,
        0,
        100,
        1,
      ],
      // Increase the heatmap color intensity by zoom level
      "heatmap-intensity": [
        "interpolate",
        ["linear"],
        ["zoom"],
        0,
        1, // ズーム0での強度
        13,
        9, // ズーム15での強度を上げる
      ],
      // Color ramp for heatmap
      "heatmap-color": [
        "interpolate",
        ["linear"],
        ["heatmap-density"],
        0, 'rgba(33, 102, 172, 0)',  // Light blue (low density, fully transparent)
        0.2, 'rgba(103, 169, 207, 0.8)', // Blue
        0.4, 'rgba(209, 229, 240, 0.8)', // Light gray-blue
        0.6, 'rgba(253, 219, 199, 0.8)', // Peach
        0.8, 'rgba(239, 138, 98, 0.8)', // Light red
        1, 'rgba(178, 24, 43, 0.8)'    // Dark red
      ],
      'heatmap-opacity': 0.8,
      // Adjust the heatmap radius by zoom level
      "heatmap-radius": [
        "interpolate",
        ["linear"],
        ["zoom"],
        0,
        5,
        10,
        15,
      ],
    },
  };

  // Layer for unclustered points (first dataset with color coding)
  const firstDatasetLayer = {
    id: "unclustered-points",
    type: "circle",
    source: "firstDataset",
    paint: {
      "circle-color": ["get", "markerColor"],
      "circle-radius": 8,
      "circle-stroke-width": 1,
      "circle-stroke-color": "#fff",
    },
  };

  const toggleMapStyle = () => {
    setMapStyle((prev) =>
      prev === "mapbox://styles/yamasnax/clubgryio000s01p98ef99wcb"
        ? "mapbox://styles/mapbox/satellite-v9"
        : "mapbox://styles/yamasnax/clubgryio000s01p98ef99wcb"
    );
  };

  const toggleClusters = () => {
    setShowClusters((prev) => !prev); // クラスター表示状態を切り替え
  };

  const toggleShowUser = () => {
    setShowUser((prev) => !prev);
  };

  const toggleShowUntaken = () => {
    setUntaken((prev) => !prev);
  };

  return (
    <div style={{ width: width, height: height }}>
      <ReactMapGL
        mapboxAccessToken={MAPBOX_TOKEN}
        style={{ width: "100%", height: "90%" }}
        mapStyle={mapStyle}
        {...viewport}
        onMove={(evt) => setViewport(evt.viewState)}
        onClick={(e) => {
          const feature = e.features && e.features.find(f => f.layer.id === "unclustered-points");
          if (feature) {
            setPopupInfo({
              ...feature.properties, // Include properties
              longitude: feature.geometry.coordinates[0], // Extract longitude from GeoJSON geometry
              latitude: feature.geometry.coordinates[1],  // Extract latitude from GeoJSON geometry
            });
          }
        }}
        interactiveLayerIds={['unclustered-points']}
      >
        <FullscreenControl />
        <NavigationControl />

        <div style={{ position: "absolute", top: 10, left: 10, zIndex: 1 }}>
          <Chip
            size="small"
            onClick={toggleMapStyle}
            label={
              mapStyle.indexOf("yamasnax") !== -1
                ? "航空写真表示"
                : "デフォルト表示"
            }
            style={{ backgroundColor: "#4caf50", color: "#fff" }}
          />
          {/* <Chip
            size="small"
            onClick={toggleClusters}
            label={showClusters ? "クラスター非表示" : "クラスター表示"}
            style={{ backgroundColor: "#4caf50", color: "#fff" }}
          /> */}
          <Chip
            size="small"
            onClick={toggleShowUser}
            label={" ユーザー"}
            style={{ backgroundColor: showUser ? "#4caf50" : "#888888", color: "#fff" }}
          />
          <Chip
            size="small"
            onClick={toggleShowUntaken}
            label={"未達"}
            style={{ backgroundColor: showUntaken ? "#4caf50" : "#888888", color: "#fff" }}
          />
        </div>

        {showUntaken &&
          <Source id="secondDataset" type="geojson" data={secondDatasetGeoJSON}>
            <Layer {...heatmapLayer} />
          </Source>
        }

        {showUser &&
          <Source id="firstDataset" type="geojson" data={firstDatasetGeoJSON}>
            <Layer {...firstDatasetLayer} />
          </Source>
        }

        {popupInfo && (
          <Popup
            anchor="top"
            longitude={Number(popupInfo.longitude)}
            latitude={Number(popupInfo.latitude)}
            onClose={() => setPopupInfo(null)}
            style={{ width: 600 }}
            className="custom-popup"
          >
            <div>
              アセットID: {popupInfo.asset_id}
              <br />
              写真数: {popupInfo.picCount}
              <br />
              撮影部位: {popupInfo.parts}
              <br />
              ユーザーID: {popupInfo.user}
              <br />
              撮影日: {popupInfo.dateTime}
            </div>
          </Popup>
        )}
      </ReactMapGL>
    </div>
  );
}

export default RealTimeMap;
