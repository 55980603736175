import { put, delay, takeEvery, all, call, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import {
  setPageTopLoader,
  setHomePictreeCheckIn,
  setHomePictreePhotoShootNtt,
} from "actions";
import { getJwtToken, getDataFromRDS, getDataFromSxi } from "api";
import { PICTREE, ENDPOINT } from "../../constants";
import * as H from "helper";
import seasonConfig from '../../utils/pictree/SeasonDataConfig';

export const currentPage = (state) => state.page.currentPage;
export const dateRange = (state) => state.page.dateRangePicTreeNtt;
export const startPrevDate = state => state.page.prevDateRangePicTree[0];
export const endPrevDate = state => state.page.prevDateRangePicTree[1];

const getIndexForDate = (data, date) => {
  const targetDate = moment(date);
  for (let i = 0; i < data.length; i++) {
      const startDate = moment(data[i].dateRange[0]);
      const endDate = moment(data[i].dateRange[1]);
      if (targetDate.isBetween(startDate, endDate, null, '[]')) {
        return i;
      }
  }
  return -1; // If no matching date range is found
};

export function* getPictreeDataHomeCheckInNtt(action) {
    try {
      yield put(setPageTopLoader(true));
      const jwtToken = yield call(getJwtToken);
      const rangeTarget = yield select(dateRange);
      const datePrevFrom = yield select(startPrevDate);
      const datePrevTo = yield select(endPrevDate);

      const sqlQueryCountCheckIn = {
        "query": `SELECT COUNT(*) FROM DenchuCheckInEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59' AND property = 'ntt'`,
        "database": "KpiDashboard",
        "type": "list"
      };
      const sqlQueryCountCheckInPrev = {
        "query": `SELECT COUNT(*) FROM DenchuCheckInEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59' AND property = 'ntt'`,
        "database": "KpiDashboard",
        "type": "list"
      };
      const sqlQueryCountCheckInKisu = {
        "query": `SELECT COUNT(DISTINCT denchuId) FROM DenchuCheckInEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59' AND property = 'ntt'`,
        "database": "KpiDashboard",
        "type": "list"
      };
      const sqlQueryCountCheckInKisuPrev = {
        "query": `SELECT COUNT(DISTINCT denchuId) FROM DenchuCheckInEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59' AND property = 'ntt'`,
        "database": "KpiDashboard",
        "type": "list"
      };
      const sqlQueryCountCheckInUniqueUser = {
        "query": `SELECT COUNT(DISTINCT deviceId) FROM DenchuCheckInEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59' AND property = 'ntt'`,
        "database": "KpiDashboard",
        "type": "list"
      };
      const sqlQueryCountCheckInUniqueUserPrev = {
        "query": `SELECT COUNT(DISTINCT deviceId) FROM DenchuCheckInEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59' AND property = 'ntt'`,
        "database": "KpiDashboard",
        "type": "list"
      };
      const sqlQueryCountMostCheckIn = {
        "query": `SELECT denchuId, COUNT(*) AS transaction_count FROM DenchuCheckInEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59' AND property = 'ntt'
                  GROUP BY denchuId ORDER BY transaction_count DESC LIMIT 1;`,
        "database": "KpiDashboard",
        "type": "list"
      };
      const sqlQueryCountMostCheckInUser = {
        "query": `SELECT uid, COUNT(*) AS transaction_count FROM DenchuCheckInEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59' AND property = 'ntt'
                  GROUP BY uid ORDER BY transaction_count DESC LIMIT 1;`,
        "database": "KpiDashboard",
        "type": "list"
      };

      const [dataHomeCheckIn, dataHomeCheckInPrev, dataHomeCheckInKisu, dataHomeCheckInKisuPrev,
          dataHomeCheckInUniqueUser, dataHomeCheckInUniqueUserPrev, dataMostCheckIn, dataMostCheckInUser ] = yield all([
          call(getDataFromRDS, sqlQueryCountCheckIn, ENDPOINT.GET_DATA_USERS, jwtToken),
          call(getDataFromRDS, sqlQueryCountCheckInPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
          call(getDataFromRDS, sqlQueryCountCheckInKisu, ENDPOINT.GET_DATA_USERS, jwtToken),
          call(getDataFromRDS, sqlQueryCountCheckInKisuPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
          call(getDataFromRDS, sqlQueryCountCheckInUniqueUser, ENDPOINT.GET_DATA_USERS, jwtToken),
          call(getDataFromRDS, sqlQueryCountCheckInUniqueUserPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
          call(getDataFromRDS, sqlQueryCountMostCheckIn, ENDPOINT.GET_DATA_USERS, jwtToken),
          call(getDataFromRDS, sqlQueryCountMostCheckInUser, ENDPOINT.GET_DATA_USERS, jwtToken),
      ]);
      const countCheckIn = JSON.parse(dataHomeCheckIn.body);
      const countCheckInValue = countCheckIn[0][0]["longValue"];
      const countCheckInPrev = JSON.parse(dataHomeCheckInPrev.body);
      const countCheckInValuePrev = countCheckInPrev[0][0]["longValue"];

      // Kisu
      const countCheckInKisu = JSON.parse(dataHomeCheckInKisu.body);
      const countCheckInKisuValue = countCheckInKisu[0][0]["longValue"];
      const countCheckInKisuPrev = JSON.parse(dataHomeCheckInKisuPrev.body);
      const countCheckInKisuValuePrev = countCheckInKisuPrev[0][0]["longValue"];

      // Unique Users
      const countCheckInUniqueUser = JSON.parse(dataHomeCheckInUniqueUser.body);
      const countCheckInUniqueUserValue = countCheckInUniqueUser[0][0]["longValue"];
      const countCheckInUniqueUserPrev = JSON.parse(dataHomeCheckInUniqueUserPrev.body);
      const countCheckInUniqueUserValuePrev = countCheckInUniqueUserPrev[0][0]["longValue"];

      // Most CheckIn Kisu
      const countCheckInMost = JSON.parse(dataMostCheckIn.body);
      const countCheckInMostValue = countCheckInMost.length === 0 ? "--" : countCheckInMost[0][0]["stringValue"];

      // Most CheckIn User
      const countCheckInMostUser = JSON.parse(dataMostCheckInUser.body);
      const countCheckInMostUserValue = countCheckInMostUser.length === 0 ? "--" : countCheckInMostUser[0][0]["stringValue"];

      const homeCheckInData = {
          checkInCount: countCheckInValue,
          checkInCountPrev: countCheckInValuePrev,
          checkInCountKisu: countCheckInKisuValue,
          checkInCountKisuPrev: countCheckInKisuValuePrev,
          checkInCountUniqueUser: countCheckInUniqueUserValue,
          checkInCountUniqueUserPrev: countCheckInUniqueUserValuePrev,
          checkInMost: countCheckInMostValue,
          checkInMostUser: countCheckInMostUserValue,
        };
      yield put(setHomePictreeCheckIn(homeCheckInData));
      yield put(setPageTopLoader(false));
    } catch (err) {
      console.log(err);
    }
}

export function* getPictreeDataHomePhotoShootNtt(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const datePrevFrom = yield select(startPrevDate);
    const datePrevTo = yield select(endPrevDate);

    const sqlQueryCountPhotoDenchu = {
      "query": `SELECT COUNT(DISTINCT denchuId) FROM DenchuPhotoShootDenchuEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59' AND property = 'ntt'`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountPhotoDenchuPrev = {
      "query": `SELECT COUNT(DISTINCT denchuId) FROM DenchuPhotoShootDenchuEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59' AND property = 'ntt'`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountUniqueUser = {
      "query": `
        SELECT COUNT(DISTINCT uid) AS unique_uid_count FROM DenchuPhotoShootDenchuEvtProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59' AND property = 'ntt'`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountUniqueUserPrev = {
      "query": `
        SELECT COUNT(DISTINCT uid) AS unique_uid_count FROM DenchuPhotoShootDenchuEvtProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59' AND property = 'ntt'`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountPicCount = {
      "query": `
        SELECT
          SUM(picCount) AS total_pic_count
        FROM (
          SELECT picCount FROM DenchuPhotoShootDenchuEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        ) AS combined_data;`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountPicCountPrev = {
      "query": `
        SELECT
          SUM(picCount) AS total_pic_count
        FROM (
          SELECT picCount FROM DenchuPhotoShootDenchuEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
        ) AS combined_data;`,
      "database": "KpiDashboard",
      "type": "list"
    };

    // dupe photoshoot - denchu
    const sqlQueryCountPhotoDupeDenchu = {
      "query": `
        SELECT COUNT(*) AS total_count
        FROM (
            SELECT denchuId
            FROM DenchuPhotoShootDenchuEvtProd
            WHERE
            CONVERT_TZ(
                FROM_UNIXTIME(logDate / 1000),
                'UTC',
                'Asia/Tokyo'
            )  BETWEEN '${rangeTarget[0]} 00:00:00'
            AND '${rangeTarget[1]} 23:59:59'
            AND property = 'ntt'
            GROUP BY denchuId
            HAVING COUNT(*) > 1
        ) AS subquery;
      `,
      "database": "KpiDashboard",
      "type": "list"
    };

    const [dataPhotoDenchu, dataPhotoDenchuPrev,
           dataCountUniue, dataCountUniuePrev, dataPicCount, dataPicCountPrev,
           dataDupePhotoDenchu] = yield all([
        call(getDataFromRDS, sqlQueryCountPhotoDenchu, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountPhotoDenchuPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountUniqueUser, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountUniqueUserPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountPicCount, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountPicCountPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountPhotoDupeDenchu, ENDPOINT.GET_DATA_USERS, jwtToken),
    ]);

    // Denchu
    const countPhotoDenchu = JSON.parse(dataPhotoDenchu.body);
    const countPhotoDenchuValue = countPhotoDenchu[0][0]["longValue"];
    const countPhotoDenchuPrev = JSON.parse(dataPhotoDenchuPrev.body);
    const countPhotoDenchuValuePrev = countPhotoDenchuPrev[0][0]["longValue"];

    // Unique Users
    const countUniqueUser = JSON.parse(dataCountUniue.body);
    const countUniqueUserValue = countUniqueUser[0][0]["longValue"];
    const countUniqueUserPrev = JSON.parse(dataCountUniuePrev.body);
    const countUniqueUserValuePrev = countUniqueUserPrev[0][0]["longValue"];

    // Pic Count
    const countPicCount = JSON.parse(dataPicCount.body);
    const countPicCountValue = countPicCount[0][0]["stringValue"];
    const countPicCountPrev = JSON.parse(dataPicCountPrev.body);
    const countPicCountValuePrev = countPicCountPrev[0][0]["stringValue"];

    // Denchu Dupe Count
    const countDupeDenchuCount = JSON.parse(dataDupePhotoDenchu.body);
    const countDupeDenchuCountValue = countDupeDenchuCount[0][0]["longValue"];

    let homePhotoShootDataNtt = {
      countDenchu: countPhotoDenchuValue,
      countDenchuPrev: countPhotoDenchuValuePrev,
      countUniqueUser: countUniqueUserValue,
      countUniqueUserPrev: countUniqueUserValuePrev,
      countPic: countPicCountValue === undefined ? 0 : parseInt(countPicCountValue),
      countPicPrev: countPicCountValuePrev === undefined ? 0 : parseInt(countPicCountValuePrev),
      countAsset: countPhotoDenchuValue,
      countAssetPrev: countPhotoDenchuValuePrev,
      countDupeDenchu: countDupeDenchuCountValue,
    };
    const index = getIndexForDate(seasonConfig.seasonConfig, rangeTarget[1]);
    let sqlQueryCountProgressDenchu = false;
    sqlQueryCountProgressDenchu = {
      "query": `SELECT COUNT(DISTINCT denchuId) FROM DenchuPhotoShootDenchuEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '2024-12-07 00:00:00' AND '${rangeTarget[1]} 23:59:59' AND property = 'ntt'`,
      "database": "KpiDashboard",
      "type": "list"
    };

    const [dataPhotoDenchuProgress] = yield all([
      call(getDataFromRDS, sqlQueryCountProgressDenchu, ENDPOINT.GET_DATA_USERS, jwtToken),
    ]);

    // Denchu Progres Count by season
    const denchuProgressCount = JSON.parse(dataPhotoDenchuProgress.body);
    const assetsProgress = denchuProgressCount[0][0]["longValue"];

    homePhotoShootDataNtt.progressDenchu = {
      "taken": assetsProgress,
      "max": 2957,
      "maxByasset": 2957, //dataAllAssetReg
    }

    yield put(setHomePictreePhotoShootNtt(homePhotoShootDataNtt));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export default function* watchPageSettings() {
  yield takeEvery(PICTREE.GET_HOME_CHECKIN_NTT, getPictreeDataHomeCheckInNtt);
  yield takeEvery(PICTREE.GET_HOME_PHOTOSHOOT_NTT, getPictreeDataHomePhotoShootNtt);
}
