import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { GetApp } from '@material-ui/icons';
import { CSVLink } from "react-csv";
import * as C from 'components';

const useStyles = makeStyles({
  loaderBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderBarImg: {
    width: 40,
  },
  tableContainer: {
    display: 'flex',
    minHeight: 200,
  },
  dateTableWrapper: {
    float: 'left',
  },
  dataTableWrapper: {
    float: 'left',
    overflow: 'scroll',
  },
  leftTable: {
    border: '1px solid #eee',
  },
  lblGameName: {
    color: '#000',
    fontSize: 13,
    minWidth: 200,
  },
  lblUniqueUser: {
    color: '#6f6969',
    fontSize: 11,
    minWidth: 180,
  },
  _GAIv: {
    height: 40,
    backgroundColor: "#4#4#4",
    borderLeft: "1px solid #eee6e6",
    color: "#666",
    fontSize: 11,
    minWidth: 80,
    paddingRight: 10,
    textAlign: 'right',
  },
  dataHeaderTd: {
    width: 100,
    paddingRight: 10,
    borderBottom: '1px solid #eee6e6',
  },
  dataTableBody: {
    fontSize: 11,
    paddingRight: 10,
    paddingBottom: 0,
    textAlign: 'right',
    verticalAlign: 'middle',
    border: '1px solid #eee6e6',
    height: '3em',
    overflowX: 'auto',
  },
  dataCellTd: {
    fontSize: 13,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 10,
    textAlign: 'right',
    verticalAlign: 'middle',
    borderBottom: '1px solid #eee6e6',
    borderRight: '1px solid #eee6e6',
    height: 45,
  },
  tTdHeader: {
    paddingLeft: '1rem',
    paddingRight: 19,
    fontWeight: 'bold',
    height: 39,
  },
  tTd: {
    height: 44,
    width: 250,
    paddingLeft: '1rem',
    borderBottom: '1px solid #eee',
    borderCollapse: 'collapse',
  },
  tableNumberData: {
    fontSize: 13,
    paddingBottom: 4,
  },
  exportBtn: {
    marginLeft: 16,
  },
  titleContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  widgetTitle: {
    flexBasis: '50%',
    fontSize: 16,
    padding: 16,
  },
  exportBtnContainer: {
    marginLeft: 'auto',
    padding: 16,
  }
});

const PicTreeTablePhotoShootNtt = ({
  photoShootTrend,
  chartSize,
  pageTopLoader,
}) => {
  const classes = useStyles();
  const getTableLabelColumn = () => {
    return  <>
              <tr>
                <td className={classes.tTd}>
                  <div className={classes.lblGameName}>電柱撮影数</div>
                </td>
              </tr>
              <tr>
                <td className={classes.tTd}>
                  <div className={classes.lblGameName}>ユニークユーザー数</div>
                </td>
              </tr>
            </>
  }

  const getDataTable = (photoShootTrend) => {
    return  <>
              <tr>
              {photoShootTrend[0]["countDenchu"].map((d, i) =>
                <td className={classes.dataCellTd} key={i}>{d}</td>
              )}
              </tr >
              <tr>
              {photoShootTrend[0]["unique"].map((d, i) =>
                <td className={classes.dataCellTd} key={i}>{d}</td>
              )}
              </tr >
            </>
  }

  const getExportData = (photoShootTrend) => {
    let exportData = [];
    if (photoShootTrend.length > 0) {
      let headerArray = ["通常モード撮影データ"];
      let numOfHeaderData = photoShootTrend[0]['date'].length;
      for (let j=0; j<numOfHeaderData; j++) {
        headerArray.push(
          chartSize !== "hour"
            ? moment(photoShootTrend[0]['date'][j]).format('M/D')
            : moment(photoShootTrend[0]['date'][j]).format('M/D  HH:mm')
        );
      }
      exportData.push(headerArray);

      // Denchu
      let countArray = ["電柱撮影数"];
      for (let j=0; j<numOfHeaderData; j++) {
        countArray.push(photoShootTrend[0]['countDenchu'][j]);
      }
      exportData.push(countArray);

      // Unique Paid Users
      let upuArray = ["ユニークユーザー数"];
      for (let j=0; j<numOfHeaderData; j++) {
        upuArray.push(photoShootTrend[0]['unique'][j]);
      }
      exportData.push(upuArray);
    }

    return exportData;
  }

  return (
    <Paper>
      <div className={classes.titleContainer}>
        <div className={classes.widgetTitle}></div>
        <div className={classes.exportBtnContainer}>
          <CSVLink
              data={getExportData(photoShootTrend)}
              filename={`PicTree - 通常モード撮影トレンドデータ`}
          >
            <Button
                variant="contained"
                color="default"
                className={classes.exportBtn}
                startIcon={<GetApp />}
            >
              CSV出力
            </Button>
          </CSVLink>
        </div>
      </div>
      <div className={classes.tableContainer}>
      {pageTopLoader
        ? <div className={classes.loaderBox}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div>
        : photoShootTrend.length > 0 ?
        photoShootTrend.length > 0 ?
              <>
                <div className={classes.dateTableWrapper}>
                  <table className={classes.leftTable}>
                      <tbody>
                      <tr>
                          <td className={classes.tTdHeader}>
                          </td>
                      </tr >
                      {getTableLabelColumn(photoShootTrend)}
                      </tbody>
                  </table>
                </div>

                <div className={classes.dataTableWrapper}>
                  <table className={classes.dataTableBody}>
                    <tbody>
                      <tr className={classes._GAIv}>
                      {photoShootTrend[0]['date'].map((item, i) => (
                        <td className={classes.dataHeaderTd} key={i}>
                          {chartSize !== "hour"
                            ? moment(item).format('M/D')
                            : moment(item).format('M/D HH:mm')}
                        </td>
                      ))}
                      </tr >
                        {getDataTable(photoShootTrend)}
                    </tbody>
                  </table>
                </div>
              </>
              : <div className={classes.loaderBox}><C.NoData text="No Data" /></div>
            : null
        }
      </div>
    </Paper>
  )
}

PicTreeTablePhotoShootNtt.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
  photoShootTrend: state.pictree.photoShootTrend,
  chartSize: state.pictree.chartSize,
  pageTopLoader: state.page.pageTopLoader,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(PicTreeTablePhotoShootNtt);
