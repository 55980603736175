import { put, delay, takeEvery, all, call, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import {
  setPageTopLoader,
  setPicTreeUserListData,
} from "actions";
import { getJwtToken, getDataFromRDS, getDataFromSxi } from "api";
import { PICTREE, ENDPOINT } from "../../constants";
import * as H from "helper";

export const currentPage = (state) => state.page.currentPage;
export const dateRange = (state) => state.page.dateRangePicTree;
export const startPrevDate = (state) => state.page.prevDateRangePicTree[0];
export const endPrevDate = (state) => state.page.prevDateRangePicTree[1];
export const usersListData = (state) => state.pictree.usersList;

export function* getPictreeUsersListData(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const offSetNumber = (action.payload - 1) * 50;
    const rangeTarget = yield select(dateRange);
    const datePrevFrom = yield select(startPrevDate);
    const datePrevTo = yield select(endPrevDate);

    const sqlCountTotalUsers = {
      query: `SELECT COUNT(*) as ttl FROM player ply JOIN player_state ps ON ply.id = ps.player_id`,
      database: "KpiDashboard",
      type: "list",
    };

    const sqlGetUsers = {
      query: `
        SELECT
          *
        FROM player ply
        JOIN player_state ps ON ply.id = ps.player_id
        LEFT JOIN account_connect_for_playmining acnp ON ply.id = acnp.player_id
        WHERE ps.status = 'ACTIVE'
        ORDER BY ply.registered_datetime DESC LIMIT 50 OFFSET ${offSetNumber}`,
      database: "KpiDashboard",
      type: "list",
    };

    const [tempUsersTtlCount, tempUsersList] = yield all([
      call(getDataFromSxi, sqlCountTotalUsers, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      call(getDataFromSxi, sqlGetUsers, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
    ]);

    let userListData = []; let eachUser = {};
    tempUsersList.forEach((user) => {
      eachUser = {
        "player_id": user.id,
        "username": user.name,
        "pmid": user.pmid !== null ? user.pmid : "n/a",
        "authentication_id": user.authentication_id,
        "status": user.status,
        "reg_datetime": moment(user.registered_datetime).tz("Asia/Tokyo").format('YYYY/MM/DD hh:mm A'),
      }
      userListData.push(eachUser);
      eachUser = {};
    });

    const userListPageData = {
      "total": tempUsersTtlCount[0]['ttl'],
      "userListData": userListData
    }

    yield put(setPicTreeUserListData(userListPageData));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getPictreeUserData(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const currentUsersListData = yield select(usersListData);
    const userId = action.payload[0]["value"];

    const sqlGetUser = {
      query: `
        SELECT * FROM player ply
        JOIN player_state ps ON ply.id = ps.player_id
        JOIN account_connect_for_playmining acnp ON ply.id = acnp.player_id
        WHERE ps.player_id = '${userId}'`,
      database: "KpiDashboard",
      type: "list",
    };

    const [tempUser] = yield all([
      call(getDataFromSxi, sqlGetUser, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
    ]);

    let userListData = []; let eachUser = {};
    tempUser.forEach((user) => {
      eachUser = {
        "player_id": user.player_id,
        "username": user.name,
        "pmid": user.pmid,
        "authentication_id": user.authentication_id,
        "team": user.team_code,
        "status": user.status,
        "reg_datetime": moment(user.registered_datetime).tz("Asia/Tokyo").format('YYYY/MM/DD hh:mm A'),
      }
      userListData.push(eachUser);
      eachUser = {};
    });

    const userListPageData = {
      "total": currentUsersListData.total,
      "userListData": userListData,
      "userFilteredTotal": userListData.length,
    }

    yield put(setPicTreeUserListData(userListPageData));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export default function* watchPageSettings() {
  yield takeEvery(PICTREE.GET_USER_LIST, getPictreeUsersListData);
  yield takeEvery(PICTREE.GET_USER_DATA, getPictreeUserData);
}
