import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, MenuItem, Select, FormControl, FormControlLabel, Radio, RadioGroup, Typography, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment-timezone';
import { setDateRangePicTree, setSelectedSeason, setSelectedMission, getSeasonData, getMissionData, setPeriodType } from "actions";

const useStyles = makeStyles((theme) =>
  createStyles({
    calendarWrapper: {
      paddingLeft: 8,
    },
    root: {
      backgroundColor: "#fafafa",
      zIndex: 1000,
      paddingTop: 16,
      paddingLeft: 8,
      marginTop: 0,
    },
    dataBtn: {
      marginTop: theme.spacing(1.2),
      marginLeft: theme.spacing(1),
      height: theme.spacing(4),
      paddingTop: theme.spacing(1.5),
      color: "#ffffff",
    },
    formControl: {
      marginTop: theme.spacing(-1.2),
    },
    label: {
      fontSize: 18,
      marginLeft: theme.spacing(-1),
    },
    selectControl: {
      marginTop: theme.spacing(-.5),
      marginLeft: theme.spacing(0),
      minWidth: 250,
    },
    missionSelect: {
      marginTop: theme.spacing(-1.5),
      minWidth: 450,
    },
    missionUnSelect: {
      marginTop: theme.spacing(-1.5),
      minWidth: 450,
    },
    missionAllSelect: {
      marginTop: theme.spacing(0),
      width: 450,
    }
  })
);

const Period = ({
  setDateRangePicTree,
  selectedSeason,
  setSelectedSeason,
  getSeasonData,
  getMissionData,
  seasonData,
  missionData,
  selectedMission,
}) => {
  const classes = useStyles();
  const [error, setError] = useState([null, null]);
  const [periodType, setPeriodType] = useState("season");

  useEffect(() => {
    getSeasonData();
    // getMissionData();
  }, []);

  useEffect(() => {
    if (seasonData.length > 0) {
      const today = moment().tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
      const matchedSeason = seasonData.find(season => {
        const startDate = season.seasonStart;
        const endDate = season.seasonEnd;
        return today >= startDate && today <= endDate;
      });
      if (matchedSeason) {
        setSelectedSeason(matchedSeason.seasonId);
      } else {
        // @todo none exist so I need to 
        setSelectedSeason('all');
      }
    }
  }, [seasonData]);


  const handleChange = event => {
    setPeriodType(event.target.value);
  };

  const handleSelecSeasonChange = (event) => {
    setSelectedSeason(event.target.value);
  };

  // const handleSelectMissionChange = (event) => {
  //   setSelectedMission(event);
  // };

  const isStarted = (startTime, currentTime) => {
    return startTime.isBefore(currentTime);
  };

  return (
    <div className={classes.calendarWrapper}>
      <div className={classes.root}>
        <Grid container spacing={1} direction="row">
          <Grid item spacing={1}>
            シーズン：
          </Grid>
          {/* <Grid item>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                name="period"
                className={classes.group}
                value={periodType}
                onChange={handleChange}
                row
              >
                <FormControlLabel className={classes.label} value="season" control={<Radio />} label="シーズン" />
                <FormControlLabel className={classes.label} value="mission" control={<Radio />} label="ミッション" />
              </RadioGroup>
            </FormControl>
          </Grid> */}
          <Grid item>
            <FormControl className={classes.selectControl}>
              {periodType === "season"
                ?
                <Select
                  labelId="season-select-label"
                  value={selectedSeason}
                  onChange={handleSelecSeasonChange}
                  displayEmpty
                >
                  <MenuItem value="blank" disabled>シーズンを選択する</MenuItem>
                  {seasonData.map((s, index) => (
                    <MenuItem
                      key={index}
                      value={s.seasonId}
                    >
                      {s.seasonName} : {s.seasonStart} ~ {s.seasonEnd}
                    </MenuItem>
                  ))}
                </Select>
                : // mission dropdown
                null
                // <Autocomplete
                //   id="mission-select-autocomplete"
                //   options={missionData}
                //   getOptionLabel={(option) => option.missionName || ""}
                //   getOptionSelected={(option, value) => option.missionId.toString() === value.toString()}
                //   value={missionData.find(mission => mission.missionId.toString() === selectedMission) || null}
                //   onChange={(event, newValue) => {
                //     if (newValue) {
                //       handleSelectMissionChange(newValue.missionId.toString());
                //     } else {
                //       handleSelectMissionChange("blank");
                //     }
                //   }}
                //   renderInput={(params) => (
                //     <TextField
                //       {...params}
                //       label={`ミッションを選択する (登録 ${missionData.length}件)`}
                //       className={selectedMission === "blank"
                //         ? classes.missionUnSelect
                //         : selectedMission === "all"
                //           ? classes.missionAllSelect
                //           : classes.missionSelect}
                //     />
                //   )}
                //   renderOption={(option) => (
                //     <div style={{ display: 'flex', flexDirection: 'column', width: "450px" }}>
                //       <Typography variant="subtitle1">
                //         {option.missionName}
                //       </Typography>
                //       <Typography variant="body1" color="textSecondary" style={{ marginLeft: 14 }}>
                //         {moment(option.missionStart).format("YYYY-MM-DD HH:mm")} ~ {moment(option.missionEnd).format("YYYY-MM-DD HH:mm")}
                //       </Typography>
                //     </div>
                //   )}
                //   getOptionDisabled={(option) => {
                //     const startTime = moment(option.missionStart, "YYYY-MM-DD HH:mm")
                //     const currentTime = moment().tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
                //     return option.missionId.toString() === "blank" || !isStarted(startTime, currentTime);;
                //   }}
                //   className={classes.missionAllSelect}
                // />
              }
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

Period.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dateRangePicTree: state.pictree.dateRangePicTree,
  periodType: state.pictree.periodType,
  seasonData: state.pictree.seasonData,
  missionData: state.pictree.missionData,
  selectedSeason: state.pictree.selectedSeason,
  selectedMission: state.pictree.selectedMission,
});

const mapDispatchToProps = (dispatch) => ({
  setDateRangePicTree:(param) => dispatch(setDateRangePicTree(param)),
  setPeriodType: (param) => dispatch(setPeriodType(param)),
  getSeasonData: () => dispatch(getSeasonData()),
  getMissionData: () => dispatch(getMissionData()),
  setSelectedSeason: (param) => dispatch(setSelectedSeason(param)),
  setSelectedMission: (param) => dispatch(setSelectedMission(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(Period));
