import { put, delay, takeEvery, all, call, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import { setPageTopLoader, setPictreeDataCheckInTrend, setPictreeDataCheckInTable, setPictreeDataCheckInMap,
  setPictreeDataCheckInTrendNtt } from "actions";
import { getJwtToken, getDataFromRDS, getDataFromSxi } from "api";
import { PICTREE, ENDPOINT } from "../../constants";
import * as H from "helper";
import axios from 'axios';

export const currentPage = (state) => state.page.currentPage;
export const dateRange = (state) => state.page.dateRangePicTree;
export const dateRangeNtt = (state) => state.page.dateRangePicTreeNtt;

export function* getPictreeDataCheckInTrend(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const propertyQuery = `AND (property = 'tepco' OR property IS NULL)`

    let sqlQueryCheckInTrend = {
      query: `
        SELECT DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')) AS DAY, COUNT(*) AS DAU
        FROM DenchuCheckInEvtProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59' ${propertyQuery}
        GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'))
        ORDER BY logDate ASC;`,
      database: "KpiDashboard",
      type: "list",
    };

    if (action.payload === "hour") {
      sqlQueryCheckInTrend.query = `
        SELECT
          DATE_FORMAT(
              CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'),
              '%Y-%m-%d %H:00'
          ) AS HOUR,
          COUNT(*) AS checkin_count
        FROM
          DenchuCheckInEvtProd
        WHERE
          CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
          BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59' ${propertyQuery}
        GROUP BY
          HOUR
        ORDER BY
          HOUR ASC;
      `;
    };

    let sqlQueryCheckInUniqueTrend = {
      query: `
        SELECT DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')) AS DAY, COUNT(DISTINCT deviceId) AS DAU
        FROM DenchuCheckInEvtProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59' ${propertyQuery}
        GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'))
        ORDER BY logDate ASC;`,
      database: "KpiDashboard",
      type: "list",
    };

    if (action.payload === "hour") {
      sqlQueryCheckInUniqueTrend.query = `
        SELECT
          DATE_FORMAT(
              CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'),
              '%Y-%m-%d %H:00'
          ) AS HOUR,
          COUNT(DISTINCT uid) AS DAU
        FROM
          DenchuCheckInEvtProd
        WHERE
            CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
            BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59' ${propertyQuery}
        GROUP BY
            HOUR
        ORDER BY
            HOUR ASC;
      `;
    };

    const [dataTempCheckInTrend, dataTempCheckInUniqueTrend] = yield all([
     call(getDataFromRDS, sqlQueryCheckInTrend, ENDPOINT.GET_DATA_USERS, jwtToken),
     call(getDataFromRDS, sqlQueryCheckInUniqueTrend, ENDPOINT.GET_DATA_USERS, jwtToken),
    ]);
    const dataCheckInTrend = JSON.parse(dataTempCheckInTrend.body);
    const dataCheckInUniqueTrend = JSON.parse(dataTempCheckInUniqueTrend.body);

    // Set xAxisDate
    let xAxisDate = [], dataArray = [], dataUniqueArray = [], recordArray = [];
    //const daysArray = H.FormatDateHelper.getDaysListBetweenDays(rangeTarget[0], rangeTarget[1]);
    const daysArray = action.payload === "day"
      ? H.FormatDateHelper.getDaysListBetweenDays(rangeTarget[0], rangeTarget[1])
      : H.FormatDateHelper.getHoursListBetweenDays(rangeTarget[0], rangeTarget[1]);
    _.each(daysArray, function(data) {
        xAxisDate.push(data);
    });

    xAxisDate.forEach(date => {
      // Check if the date exists in the data array
      const data = _.find(dataCheckInTrend, item => _.get(item, '[0].stringValue') === date);
      if (data) {
        // console.log(`Date: ${date}, Value: ${_.get(data, '[1].longValue')}`);
        dataArray.push(_.get(data, '[1].longValue'));
      } else {
        // console.log(`Date: ${date}, No data available`);
        dataArray.push(0);
      }
      const uniqueData = _.find(dataCheckInUniqueTrend, item => _.get(item, '[0].stringValue') === date);
      if (uniqueData) {
        dataUniqueArray.push(_.get(uniqueData, '[1].longValue'));
      } else {
        dataUniqueArray.push(0);
      }
    });

    // const checkInCount = {
    //   id: 'checkin-count',
    //   data: dataArray,
    //   date: xAxisDate,
    // };

    // const checkInUniqueCount = {
    //   id: 'checkin-unique-count',
    //   data: dataUniqueArray,
    //   date: xAxisDate,
    // };

    const checkInCountData = {
      date: xAxisDate,
      count: dataArray,
      unique: dataUniqueArray,
  };
    recordArray.push(checkInCountData);

    yield put(setPictreeDataCheckInTrend(recordArray));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getPictreeDataCheckInMap(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const propertyQuery = `AND (property = 'tepco' OR property IS NULL)`

    const sqlQueryCheckIn = {
      query: `SELECT * FROM DenchuCheckInEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59' ${propertyQuery}
      GROUP BY denchuId
      ORDER BY logDate DESC;`,
      database: "KpiDashboard",
      type: "list",
    };

    const [dataCheckIn] = yield all([
     call(getDataFromRDS, sqlQueryCheckIn, ENDPOINT.GET_DATA_USERS, jwtToken),
    ]);
    const countCheckIn = JSON.parse(dataCheckIn.body);
    let recordArray = [], eachData = {};
    _.each(countCheckIn, function(data, index) {
        eachData.user = data[2]["stringValue"];
        eachData.asset_id = data[4]["stringValue"];
        eachData.latitude = parseFloat(data[8]["stringValue"]);
        eachData.longitude = parseFloat(data[9]["stringValue"]);
        eachData.dateTime = moment(data[10]["longValue"]).tz("Asia/Tokyo").format('YYYY/MM/DD hh:mm A');
        recordArray.push(eachData);
        eachData = {};
    });

    yield put(setPictreeDataCheckInMap(recordArray));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}


export function* getPictreeDataCheckInTable(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const propertyQuery = `AND (property = 'tepco' OR property IS NULL)`

    const sqlQueryCheckInTrend = {
      query: `
        SELECT *
        FROM DenchuCheckInEvtProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59' ${propertyQuery}
        ORDER BY logDate DESC LIMIT 20;`,
      database: "KpiDashboard",
      type: "list",
    };

    const [dataTempCheckIn] = yield all([
     call(getDataFromRDS, sqlQueryCheckInTrend, ENDPOINT.GET_DATA_USERS, jwtToken),
    ]);
    const dataCheckIn = JSON.parse(dataTempCheckIn.body);

    let recordArray = [], eachData = {};
    for (let data of dataCheckIn) {
      const response = yield axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${parseFloat(data[8]["stringValue"])}&lon=${parseFloat(data[9]["stringValue"])}&format=json&accept-language=ja`);
      eachData.user = data[2]["stringValue"];
      eachData.asset_id = data[4]["stringValue"];
      eachData.address = response.data.display_name;
      eachData.dateTime = moment(data[10]["longValue"]).tz("Asia/Tokyo").format('YYYY/MM/DD hh:mm A');
      recordArray.push(eachData);
      eachData = {};
    }
    yield put(setPictreeDataCheckInTable(recordArray));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getPictreeDataCheckInTrendNtt(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRangeNtt);

    let sqlQueryCheckInTrend = {
      query: `
        SELECT DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')) AS DAY, COUNT(*) AS DAU
        FROM DenchuCheckInEvtProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        AND property = 'ntt'
        GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'))
        ORDER BY logDate ASC;`,
      database: "KpiDashboard",
      type: "list",
    };

    if (action.payload === "hour") {
      sqlQueryCheckInTrend.query = `
        SELECT
          DATE_FORMAT(
              CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'),
              '%Y-%m-%d %H:00'
          ) AS HOUR,
          COUNT(*) AS checkin_count
        FROM
          DenchuCheckInEvtProd
        WHERE
          CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
          BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
          AND property = 'ntt'
        GROUP BY
          HOUR
        ORDER BY
          HOUR ASC;
      `;
    };

    let sqlQueryCheckInUniqueTrend = {
      query: `
        SELECT DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')) AS DAY, COUNT(DISTINCT deviceId) AS DAU
        FROM DenchuCheckInEvtProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        AND property = 'ntt'
        GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'))
        ORDER BY logDate ASC;`,
      database: "KpiDashboard",
      type: "list",
    };

    if (action.payload === "hour") {
      sqlQueryCheckInUniqueTrend.query = `
        SELECT
          DATE_FORMAT(
              CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'),
              '%Y-%m-%d %H:00'
          ) AS HOUR,
          COUNT(DISTINCT uid) AS DAU
        FROM
          DenchuCheckInEvtProd
        WHERE
            CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
            BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
            AND property = 'ntt'
        GROUP BY
            HOUR
        ORDER BY
            HOUR ASC;
      `;
    };

    const [dataTempCheckInTrend, dataTempCheckInUniqueTrend] = yield all([
     call(getDataFromRDS, sqlQueryCheckInTrend, ENDPOINT.GET_DATA_USERS, jwtToken),
     call(getDataFromRDS, sqlQueryCheckInUniqueTrend, ENDPOINT.GET_DATA_USERS, jwtToken),
    ]);
    const dataCheckInTrend = JSON.parse(dataTempCheckInTrend.body);
    const dataCheckInUniqueTrend = JSON.parse(dataTempCheckInUniqueTrend.body);

    // Set xAxisDate
    let xAxisDate = [], dataArray = [], dataUniqueArray = [], recordArray = [];
    const daysArray = action.payload === "day"
      ? H.FormatDateHelper.getDaysListBetweenDays(rangeTarget[0], rangeTarget[1])
      : H.FormatDateHelper.getHoursListBetweenDays(rangeTarget[0], rangeTarget[1]);
    _.each(daysArray, function(data) {
        xAxisDate.push(data);
    });

    xAxisDate.forEach(date => {
      const data = _.find(dataCheckInTrend, item => _.get(item, '[0].stringValue') === date);
      if (data) {
        dataArray.push(_.get(data, '[1].longValue'));
      } else {
        dataArray.push(0);
      }
      const uniqueData = _.find(dataCheckInUniqueTrend, item => _.get(item, '[0].stringValue') === date);
      if (uniqueData) {
        dataUniqueArray.push(_.get(uniqueData, '[1].longValue'));
      } else {
        dataUniqueArray.push(0);
      }
    });

    const checkInCountData = {
      date: xAxisDate,
      count: dataArray,
      unique: dataUniqueArray,
  };
    recordArray.push(checkInCountData);
    yield put(setPictreeDataCheckInTrend(recordArray));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export default function* watchPageSettings() {
  yield takeEvery(PICTREE.GET_CHECKIN_TREND, getPictreeDataCheckInTrend);
  yield takeEvery(PICTREE.GET_CHECKIN_MAP, getPictreeDataCheckInMap);
  yield takeEvery(PICTREE.GET_CHECKIN_TABLE, getPictreeDataCheckInTable);
  yield takeEvery(PICTREE.GET_CHECKIN_TREND_NTT, getPictreeDataCheckInTrendNtt);
}
