import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Breadcrumbs,
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
  Hidden,
} from "@material-ui/core";
import moment from "moment-timezone";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import HomeStyles from "pages/HomeStyles";
import classNames from "classnames";
import {
  setCurrentPage,
  getMissionData,
  setPictreeView,
} from "actions";
import * as C from "components";
import MissionSummary from "components/PicTree/Mission/MissionSummary";
import MissionTable from "components/PicTree/Mission/MissionTable";
import CalendarPicTreeMission from "components/Calendar/CalendarPicTreeMission"

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    breadcrumbs: {
      marginTop: 16,
      minHeight: 30,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    selectGameTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  })
);

const PicTreeMissionSummary = ({
  setCurrentPage,
  pageTopLoader,
  getMissionData,
  setPictreeView,
}) => {
  const classes = useStyles();
  const [chartState, setChartState] = useState(true);
  const [chartSize, setChartSize] = useState({ name: "日別", value: "day" });

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    getMissionData();
    // setPictreeView("photo/chart");
  }, []);

  return (
    <Fragment>
      <C.ContentWrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Breadcrumbs separator="›" className={classes.breadcrumbs}>
              <Typography>PicTrée</Typography>
              <Typography variant="body1">撮影</Typography>
              <Typography variant="body1">ミッションモード</Typography>
              <Typography variant="body1">概要</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={classes.fillRemailSpace}></Grid>
          <Grid item>
            <CalendarPicTreeMission mDate={"2024/09/14"} page="pictree" pictree />
          </Grid>
        </Grid>

        <Hidden smDown>
          <Grid container justifyContent="center" spacing={0} style={{ marginTop: 0 }}>
            <Grid item xs={12} md={12} lg={10}>
              <Card>
                <CardContent>
                  <div className={classes.cardContentSection}>
                    <MissionSummary />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Hidden>

        <Grid
          container
          spacing={1}
          direction="row"
          style={{ marginTop: 16, marginRight: 16, marginBottom: 8 }}
          justifyContent="center"
        >
          <Grid item xs={12} md={12} lg={10} style={{ marginTop: 0, justifyContent: "center" }}>
            <MissionTable />
          </Grid>
        </Grid>
      </C.ContentWrapper>
    </Fragment>
  );
};

PicTreeMissionSummary.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getMissionData: () => dispatch(getMissionData()),
  setPictreeView: (param) => dispatch(setPictreeView(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(HomeStyles, { withTheme: true })(PicTreeMissionSummary));
