import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Breadcrumbs, Grid, Typography, Chip } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import OverviewStyles from "pages/NftDep/Auction/OverviewStyles";
import {
  setCurrentPage,
  getSalesOverviewDataPicTree,
  getSalesTrendDataPicTree,
  getSalesTableDataPicTree,
  setPictreeView,
} from "actions";
import * as C from "components";
import * as V from "styles/variables";
import ChartConfig from "utils/ChartConfig";
import SalesStats from "components/PicTree/Kakin/SalesStats";
import LineChartPictreeSales from "components/Charts/LineChartPictreeSales";
import PicTreeTable from "components/Table/PicTreeTable";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    position: "-webkit-sticky",
    position: "sticky",
    top: 0,
    zIndex: 1000,
  },
  titleTodaysSales: {
    paddingTop: 8,
    paddingBottom: 16,
    fontSize: 12,
  },
  fillRemailSpace: {
    flex: "auto",
  },
  sectionTop: {
    marginTop: -16,
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
    },
  },
  breadcrumbs: {
    marginTop: 16,
    minHeight: 30,
  },
  loaderBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 280,
  },
  loaderBarImg: {
    width: 40,
  },
  dataCellTd: {
    fontSize: 13,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 10,
    textAlign: "right",
    verticalAlign: "middle",
    borderBottom: "1px solid #eee6e6",
    borderRight: "1px solid #eee6e6",
    height: 45,
  },
  lblGameName: {
    color: "#000",
    fontSize: 13,
  },
  tTd: {
    height: 44,
    width: 250,
    paddingLeft: "1rem",
    borderBottom: "1px solid #eee",
    borderCollapse: "collapse",
  },
}));

const PicTreeSalesOverview = ({
  setCurrentPage,
  getSalesOverviewDataPicTree,
  getSalesTrendDataPicTree,
  getSalesTableDataPicTree,
  setPictreeView,
  pageTopLoader,
  pictreeView,
  salesTrend,
  dateRangePicTree,
}) => {
  const classes = useStyles();
  const [chartState, setChartState] = useState(true);
  const [view, setView] = useState("sales/chart");
  const [initialDataCall, setInitialDataCall] = useState(true);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    setPictreeView("sales/chart");
  }, []);

  useEffect(() => {
    if (dateRangePicTree[0] !== null && dateRangePicTree[1]!== null
      && initialDataCall) {
      getSalesOverviewDataPicTree();
      getSalesTrendDataPicTree();
      setInitialDataCall(false);
    }
  }, [dateRangePicTree]);

  useEffect(() => {
    if (pictreeView === "sales/chart") {
      getSalesTrendDataPicTree();
    } else if (pictreeView === "sales/table") {
      getSalesTableDataPicTree();
    }
  }, [pictreeView]);

  const getExportData = (data) => {
    let exportData = [];
    if (data.length > 0) {
      let headerArray = ["コイン売上データ"];
      let numOfHeaderData = data[0]["date"].length;
      for (let j = 0; j < numOfHeaderData; j++) {
        headerArray.push(data[0]["date"][j]);
      }
      exportData.push(headerArray);

      let countArray = ["コイン額"];
      for (let j = 0; j < numOfHeaderData; j++) {
        countArray.push(data[0]["amountCoins"][j]);
      }
      exportData.push(countArray);

      let upuArray = ["取引件数"];
      for (let j = 0; j < numOfHeaderData; j++) {
        upuArray.push(data[0]["countTx"][j]);
      }
      exportData.push(upuArray);
    }
    return exportData;
  };

  const getDataTable = (data) => {
    return (
      <>
        <tr>
          {data[0]["amountCoins"].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["countTx"].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["unique"].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
      </>
    );
  };

  const getTableLabelColumn = () => {
    return (
      <>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>コイン額</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>取引件数</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>ユニークユーザー数</div>
          </td>
        </tr>
      </>
    );
  };

  return (
    <C.ContentWrapper>
      <Grid
        container
        direction="row"
        className={classes.sectionTop}
        justifyContent="space-between"
      >
        <Grid item>
          <Breadcrumbs separator="›" className={classes.breadcrumbs}>
            <Typography>PicTrée</Typography>
            <Typography variant="body1">課金</Typography>
            <Typography variant="body1">売上概要</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item className={classes.fillRemailSpace}></Grid>
        <Grid item>
          <C.CalendarRangePicker
            mDate={"2024/04/13"}
            page="pictree/sales/overview"
            pictree
          />
        </Grid>
      </Grid>

      <SalesStats loading={pageTopLoader} />

      <>
        <Grid container spacing={1} className={classes.gridSection}>
          <Grid item xs={12} md={12} lg={12}>
            {pageTopLoader ? (
              <div className={classes.loaderBox}>
                <img
                  className={classes.loaderBarImg}
                  src={`/static/images/loader-bar.gif`}
                />
              </div>
            ) : chartState ? (
              salesTrend.length > 0 ? (
                <LineChartPictreeSales
                  legend={ChartConfig.legendPicTreeSalesTrend}
                  lblSaveImage={""}
                  xAisData={salesTrend[0]["date"]}
                  seriesData={salesTrend}
                  chartType={"line"}
                />
              ) : (
                <div className={classes.selectGameTxt}>
                  <Chip
                    label="No Data"
                    style={{
                      color: V.textColor,
                      backgroundColor: V.placeholderColor,
                    }}
                  />
                </div>
              )
            ) : null}
          </Grid>
        </Grid>

        <Grid container spacing={1} className={classes.gridSection}>
          <Grid item xs={12} md={12} lg={12}>
            <PicTreeTable
              game="PicTree"
              dataLabel={["コイン額", "取引件数", "ユニークユーザー数"]}
              data={salesTrend}
              tableKey={["amountCoins", "countTx", "unique"]}
              csvFileName="PicTree - コイン売上トレンドデータ"
              getExportData={getExportData}
              getDataTable={getDataTable}
              getTableLabelColumn={getTableLabelColumn}
            />
          </Grid>
        </Grid>
      </>
    </C.ContentWrapper>
  );
};

PicTreeSalesOverview.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  loading: state.nftDep.loading,
  pageTopLoader: state.page.pageTopLoader,
  pictreeView: state.pictree.pictreeView,
  salesTrend: state.pictree.salesTrend,
  dateRangePicTree: state.page.dateRangePicTree,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getSalesOverviewDataPicTree: () => dispatch(getSalesOverviewDataPicTree()),
  getSalesTrendDataPicTree: () => dispatch(getSalesTrendDataPicTree()),
  getSalesTableDataPicTree: () => dispatch(getSalesTableDataPicTree()),
  setPictreeView: (param) => dispatch(setPictreeView(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(OverviewStyles, { withTheme: true })(PicTreeSalesOverview));
