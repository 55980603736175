import { PICTREE } from "../../constants";
import moment from "moment";

const initialState = {
  homeDataUser: {},
  homeDataCheckIn: {},
  homeDataPhotoShoot: {},
  dataPhotoShootDetails: [],
  registTrend: [],
  registTable: [],
  usersOverview: [],
  usersList: [],
  userPageNumber: 1,
  salesOverview: [],
  salesTrend: [],
  salesTable: [],
  pointOverview: [],
  pointTrend: [],
  pointTable: [],
  checkInTrend: [],
  dataCheckInMap: [],
  dataCheckInTable: [],
  pictreeView: null,
  photoShootTrend: [],
  photoShootDenchuKaisuTrend: [],
  chartSize: "day",
  mapType: "taken",
  photoShootMapData: [],
  missionData: [],
  missionSummaryData: {},
  missionRankingData: [],
  rewardCoinSummaryData: {},
  rewardCoinData: [],
  rewardCoinRankingData: [],
  dateRangePicTree: [null, null],
  periodType: "season",
  selectedSeason: "",
  seasonData: [],
  missionData: [],
  selectedMission: "blank",
};

export default function pictreeNttReducer(state = initialState, action) {
  switch (action.type) {
    case PICTREE.SET_HOME_USER:
      return {
        ...state,
        homeDataUser: action.payload,
      };
    case PICTREE.SET_HOME_CHECKIN_NTT:
      return {
        ...state,
        homeDataCheckIn: action.payload,
      };
    case PICTREE.SET_HOME_PHOTOSHOOT_NTT:
      return {
        ...state,
        homeDataPhotoShoot: action.payload,
      };
    case PICTREE.SET_PHOTOSHOOT_DETAILS:
      return {
        ...state,
        dataPhotoShootDetails: action.payload,
      };
    case PICTREE.SET_REGIST_TREND:
      return {
        ...state,
        registTrend: action.payload,
      };
    case PICTREE.SET_REGIST_TABLE:
      return {
        ...state,
        registTable: action.payload,
      };
    case PICTREE.SET_SALES_OVERVIEW:
      return {
        ...state,
        salesOverview: action.payload,
      };
    case PICTREE.SET_SALES_TREND:
      return {
        ...state,
        salesTrend: action.payload,
      };
    case PICTREE.SET_SALES_TABLE:
      return {
        ...state,
        salesTable: action.payload,
      };
    case PICTREE.SET_POINT_OVERVIEW:
      return {
        ...state,
        pointOverview: action.payload,
      };
    case PICTREE.SET_POINT_TREND:
      return {
        ...state,
        pointTrend: action.payload,
      };
    case PICTREE.SET_POINT_TABLE:
      return {
        ...state,
        pointTable: action.payload,
      };
    case PICTREE.SET_CHECKIN_TREND_NTT:
      return {
        ...state,
        checkInTrend: action.payload,
      };
    case PICTREE.SET_CHECKIN_MAP:
      return {
        ...state,
        dataCheckInMap: action.payload,
      };
    case PICTREE.SET_CHECKIN_TABLE:
      return {
        ...state,
        dataCheckInTable: action.payload,
      };
    case PICTREE.SET_PICTREE_VIEW:
      return {
        ...state,
        pictreeView: action.payload,
      };
    case PICTREE.SET_PHOTOSHOOT_TREND:
      return {
        ...state,
        photoShootTrend: action.payload,
      };
    case PICTREE.SET_PHOTOSHOOT_DENCHU_KAISU_TREND:
      return {
        ...state,
        photoShootDenchuKaisuTrend: action.payload,
      };
    case PICTREE.SET_USER_LIST:
      return {
        ...state,
        usersList: action.payload,
      };
    case PICTREE.SET_PICTREE_USER_PAGE_NUMBER:
      return {
        ...state,
        userPageNumber: action.payload,
      };
    case PICTREE.SET_CHART_SIZE:
      return {
        ...state,
        chartSize: action.payload,
      };
    case PICTREE.SET_MAP_TYPE:
      return {
        ...state,
        mapType: action.payload,
      };
    case PICTREE.SET_PHOTOSHOOT_MAP_DATA_NTT:
      return {
        ...state,
        photoShootMapData: action.payload,
      };
    case PICTREE.SET_MISSION_DATA:
      return {
          ...state,
          missionData: action.payload
      };
    case PICTREE.SET_MISSION_SUMMARY_DATA:
      return {
          ...state,
          missionSummaryData: action.payload
      };
    case PICTREE.SET_MISSION_RANKING_DATA:
      return {
          ...state,
          missionRankingData: action.payload
      };
    case PICTREE.SET_REWARD_COIN_SUMMARY_DATA:
      return {
          ...state,
          rewardCoinSummaryData: action.payload
      };
    case PICTREE.SET_REWARD_COIN_DATA:
      return {
          ...state,
          rewardCoinData: action.payload
      };
    case PICTREE.SET_REWARD_COIN_RANKING_DATA:
      return {
          ...state,
          rewardCoinRankingData: action.payload
      };
    case PICTREE.SET_PERIOD_TYPE:
      return {
          ...state,
          periodType: action.payload
      };
    case PICTREE.SET_SELECTED_SEASON:
      return {
          ...state,
          selectedSeason: action.payload
      };
    case PICTREE.SET_SEASON_DATA:
      return {
          ...state,
          seasonData: action.payload
      };
    case PICTREE.SET_MISSION_DATA:
      return {
          ...state,
          missionData: action.payload
      };
    case PICTREE.SET_SELECTED_MISSION:
      return {
          ...state,
          selectedMission: action.payload
      };
    default:
      return state;
  }
}
