import { put, delay, takeEvery, all, call, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import {
  setPageTopLoader,
  setPointOverviewDataPicTree,
  setPointTrendDataPicTree,
} from "actions";
import { getJwtToken, getDataFromRDS, getDataFromSxi } from "api";
import { PICTREE, ENDPOINT } from "../../constants";
import * as H from "helper";

export const currentPage = (state) => state.page.currentPage;
export const dateRange = (state) => state.page.dateRangePicTree;
export const startPrevDate = (state) => state.page.prevDateRangePicTree[0];
export const endPrevDate = (state) => state.page.prevDateRangePicTree[1];

export function* getPictreeDataPointOverview(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const datePrevFrom = yield select(startPrevDate);
    const datePrevTo = yield select(endPrevDate);

    const sqlQueryRewardSum = {
      query: `
        SELECT SUM(bch.amount) AS point, COUNT(*) AS count, COUNT(distinct bce.player_id) AS player
        FROM balance_change_history bch
        JOIN balance_change_event bce ON bch.source_event_id = bce.id
        WHERE CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        AND bch.coin_type in ("REWARD") AND bch.amount > 0
      `,
      database: "KpiDashboard",
      type: "list",
    };
    const sqlQueryRewardSumPrev = {
      query: `
        SELECT SUM(bch.amount) AS point, COUNT(*) AS count, COUNT(distinct bce.player_id) AS player
        FROM balance_change_history bch
        JOIN balance_change_event bce ON bch.source_event_id = bce.id
        WHERE CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo')
        BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
        AND bch.coin_type in ("REWARD") AND bch.amount > 0
      `,
      database: "KpiDashboard",
      type: "list",
    };

    const sqlQueryPointSum = {
      query: `
        SELECT bce.type AS type, ABS(SUM(bch.amount)) AS point, COUNT(*) AS count, COUNT(distinct bce.player_id) AS player
        FROM balance_change_history bch
        JOIN balance_change_event bce ON bch.source_event_id = bce.id
        WHERE CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        AND bce.type in ("EXCHANGED_TO_DEP", "CONSUMED_BY_OPERATOR")
        GROUP BY bce.type
      `,
      database: "KpiDashboard",
      type: "list",
    };
    const sqlQueryPointSumPrev = {
      query: `
        SELECT bce.type AS type, ABS(SUM(bch.amount)) AS point, COUNT(*) AS count, COUNT(distinct bce.player_id) AS player
        FROM balance_change_history bch
        JOIN balance_change_event bce ON bch.source_event_id = bce.id
        WHERE CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo')
        BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
        AND bce.type in ("EXCHANGED_TO_DEP", "CONSUMED_BY_OPERATOR")
        GROUP BY bce.type
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // unique
    const sqlQueryPointUnique = {
      query: `
        SELECT COUNT(distinct bce.player_id) AS player
        FROM balance_change_history bch
        JOIN balance_change_event bce ON bch.source_event_id = bce.id
        WHERE CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        AND bce.type in ("EXCHANGED_TO_DEP", "CONSUMED_BY_OPERATOR")
      `,
      database: "KpiDashboard",
      type: "list",
    };

    const sqlQueryPointUniquePrev = {
      query: `
        SELECT COUNT(distinct bce.player_id) AS player
        FROM balance_change_history bch
        JOIN balance_change_event bce ON bch.source_event_id = bce.id
        WHERE CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo')
        BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
        AND bce.type in ("EXCHANGED_TO_DEP", "CONSUMED_BY_OPERATOR")
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // first time
    // const sqlQueryCountFirst = {
    //   query: `
    //     SELECT COUNT(*) AS first_time_purchase_count
    //     FROM (
    //         SELECT uid, MIN(logDate) AS first_purchase_datetime
    //         FROM DenchuGameCoinInEvtProd
    //         WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
    //         GROUP BY uid
    //     ) AS first_purchase_subquery;
    //   `,
    //   database: "KpiDashboard",
    //   type: "list",
    // };
    // const sqlQueryCountFirstPrev = {
    //   query: `
    //     SELECT COUNT(*) AS first_time_purchase_count
    //     FROM (
    //         SELECT uid, MIN(logDate) AS first_purchase_datetime
    //         FROM DenchuGameCoinInEvtProd
    //         WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
    //         GROUP BY uid
    //     ) AS first_purchase_subquery;
    //   `,
    //   database: "KpiDashboard",
    //   type: "list",
    // };

    const [tempPointAmount, tempPointAmountPrev, tempPointUni, tempPointUniPrev, tempReward, tempRewardPrev /*tempCountFirst, tempCountFirstPrev,*/ ] = yield all([
      call(getDataFromSxi, sqlQueryPointSum, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      call(getDataFromSxi, sqlQueryPointSumPrev, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      call(getDataFromSxi, sqlQueryPointUnique, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      call(getDataFromSxi, sqlQueryPointUniquePrev, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      call(getDataFromSxi, sqlQueryRewardSum, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      call(getDataFromSxi, sqlQueryRewardSumPrev, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
    ]);
    // console.log("HEY tempReward:", tempReward);
    // console.log("HEY tempRewardPrev:", tempRewardPrev);

    let totalDepEx = 0, totalAmzonEx = 0,
        totalDepCountEx = 0, totalAmzonCountEx = 0,
        totalDepUni = 0, totalAmzonUni = 0;
    _.each(tempPointAmount, function(data, index) {
      if (data.type === "EXCHANGED_TO_DEP") {
        totalDepEx = data.point;
        totalDepCountEx = data.count;
        totalDepUni = data.player;
      } else if (data.type === "CONSUMED_BY_OPERATOR") {
        totalAmzonEx = totalAmzonEx + data.point;
        totalAmzonCountEx = data.count;
        totalAmzonUni = data.player;
      }
    });

    // Prev
    let totalDepExPrev = 0, totalAmzonExPrev = 0,
        totalDepCountExPrev = 0, totalAmzonCountExPrev = 0,
        totalDepUniPrev = 0, totalAmzonUniPrev = 0;
    _.each(tempPointAmountPrev, function(data, index) {
      if (data.type === "EXCHANGED_TO_DEP") {
        totalDepExPrev = data.point;
        totalDepCountExPrev = data.count;
        totalDepUniPrev = data.player;
      } else if (data.type === "CONSUMED_BY_OPERATOR") {
        totalAmzonExPrev = data.point;
        totalAmzonCountExPrev = data.count;
        totalAmzonUniPrev = data.player;
      }
    });

    let overviewData = {};
    overviewData = {
      rewardPoint: tempReward[0].point === null ? 0 : tempReward[0].point,
      rewardPointPrev: tempRewardPrev[0].point === null ? 0 : tempRewardPrev[0].point,
      rewardPointTx: tempReward[0].count === null ? 0 : tempReward[0].count,
      rewardPointTxPrev: tempRewardPrev[0].count === null ? 0 : tempRewardPrev[0].count,
      rewardPointUnique: tempReward[0].player,
      rewardPointUniquePrev: tempRewardPrev[0].player,
      totalPoint: totalDepEx + totalAmzonEx,
      totalPointPrev: totalDepExPrev + totalAmzonExPrev,
      countTx: totalDepCountEx + totalAmzonCountEx,
      countTxPrev: totalDepCountExPrev + totalAmzonCountExPrev,
      dep: totalDepEx,
      depPrev: totalDepExPrev,
      amazon: totalAmzonEx,
      amazonPrev: totalAmzonExPrev,
      countUnique: tempPointUni[0].player,
      countUniquePrev: tempPointUniPrev[0].player,
    };
    yield put(setPointOverviewDataPicTree([overviewData]));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getPictreeDataPointTrend(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);

    const sqlQueryPointDepTrend = {
      query: `
        SELECT DATE_FORMAT(CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo'), '%Y-%m-%d') AS DAY,
        ABS(SUM(bch.amount)) AS POINT, COUNT(*) AS CNT
        FROM balance_change_history bch
        JOIN balance_change_event bce ON bch.source_event_id = bce.id
        WHERE CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59' AND bce.type in ("EXCHANGED_TO_DEP")
        GROUP BY DATE(CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo'))
      `,
      database: "KpiDashboard",
      type: "list",
    };
    const sqlQueryPointAmazonTrend = {
      query: `
        SELECT DATE_FORMAT(CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo'), '%Y-%m-%d') AS DAY,
        ABS(SUM(bch.amount)) AS POINT, COUNT(*) AS CNT
        FROM balance_change_history bch
        JOIN balance_change_event bce ON bch.source_event_id = bce.id
        WHERE CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59' AND bce.type in ("CONSUMED_BY_OPERATOR")
        GROUP BY DATE(CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo'))
      `,
      database: "KpiDashboard",
      type: "list",
    };
    const sqlQueryPointUnique = {
      query: `
        SELECT DATE_FORMAT(CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo'), '%Y-%m-%d') AS DAY,
        COUNT(distinct bce.player_id) AS PLAYER
        FROM balance_change_history bch
        JOIN balance_change_event bce ON bch.source_event_id = bce.id
        WHERE CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        AND bce.type in ("EXCHANGED_TO_DEP", "CONSUMED_BY_OPERATOR")
        GROUP BY DATE(CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo'))
      `,
      database: "KpiDashboard",
      type: "list",
    };
    const sqlQueryPointTotalGiven = {
      query: `
        SELECT DATE_FORMAT(CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo'), '%Y-%m-%d') AS DAY,
        ABS(SUM(bch.amount)) AS POINT, COUNT(*) AS CNT
        FROM balance_change_history bch
        JOIN balance_change_event bce ON bch.source_event_id = bce.id
        WHERE CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59' AND bch.coin_type in ("REWARD")
        AND bch.amount > 0
        GROUP BY DATE(CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo'))
      `,
      database: "KpiDashboard",
      type: "list",
    };

    const [dataTempDepTrend, dataTempAmazonTrend, dataTempUniqueTrend, /*dataTempTotalRewaradGiven*/] = yield all([
      call(
        getDataFromSxi,
        sqlQueryPointDepTrend,
        ENDPOINT.GET_DATA_SXI_DENCHU,
        jwtToken
      ),
      call(
        getDataFromSxi,
        sqlQueryPointAmazonTrend,
        ENDPOINT.GET_DATA_SXI_DENCHU,
        jwtToken
      ),
      call(
        getDataFromSxi,
        sqlQueryPointUnique,
        ENDPOINT.GET_DATA_SXI_DENCHU,
        jwtToken
      ),
      // call(
      //   getDataFromSxi,
      //   sqlQueryPointTotalGiven,
      //   ENDPOINT.GET_DATA_SXI_DENCHU,
      //   jwtToken
      // ),
    ]);
    // console.log("HEY dataTempTotalRewaradGiven:", dataTempTotalRewaradGiven);

    // Set xAxisDate
    let xAxisDate = [],
      rewardPointArray = [], countRewardPointArray = [],
      pointDepArray = [], countDepArray = [],
      pointAmazonArray = [], countAmazonArray = [],
      pointUniqueArray = [], recordArray = [];
    const daysArray = H.FormatDateHelper.getDaysListBetweenDays(
      rangeTarget[0],
      rangeTarget[1]
    );
    _.each(daysArray, function (data) {
      xAxisDate.push(data);
    });

    xAxisDate.forEach((date) => {
      // Check if the date exists in the data array
      // Reward coin given
      // const rewardData = _.find(
      //   dataTempTotalRewaradGiven,
      //   (item) => _.get(item, "DAY") === date
      // );
      // if (rewardData) {
      //   rewardPointArray.push(_.get(rewardData, "POINT"));
      //   countRewardPointArray.push(_.get(rewardData, "CNT"));
      // } else {
      //   rewardPointArray.push(0);
      //   countRewardPointArray.push(0);
      // }

      // Dep exchange
      const data = _.find(
        dataTempDepTrend,
        (item) => _.get(item, "DAY") === date
      );
      if (data) {
        pointDepArray.push(_.get(data, "POINT"));
        countDepArray.push(_.get(data, "CNT"));
      } else {
        pointDepArray.push(0);
        countDepArray.push(0);
      }
      // Amazon gift
      const dataAmazon = _.find(
        dataTempAmazonTrend,
        (item) => _.get(item, "DAY") === date
      );
      if (dataAmazon) {
        pointAmazonArray.push(_.get(dataAmazon, "POINT"));
        countAmazonArray.push(_.get(dataAmazon, "CNT"));
      } else {
        pointAmazonArray.push(0);
        countAmazonArray.push(0);
      }
      // Unique Player
      const dataUnique = _.find(
        dataTempUniqueTrend,
        (item) => _.get(item, "DAY") === date
      );
      if (dataUnique) {
        pointUniqueArray.push(_.get(dataUnique, "PLAYER"));
      } else {
        pointUniqueArray.push(0);
      }
    });

    const pointData = {
      date: xAxisDate,
      depPoint: pointDepArray,
      depCount: countDepArray,
      amazonPoint: pointAmazonArray,
      amazonCount: countAmazonArray,
      uniqueCount: pointUniqueArray,
      // rewardPoint: rewardPointArray,
      // rewardCount: countRewardPointArray,
    };
    recordArray.push(pointData);

    yield put(setPointTrendDataPicTree(recordArray));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export default function* watchPageSettings() {
  yield takeEvery(PICTREE.GET_POINT_OVERVIEW, getPictreeDataPointOverview);
  yield takeEvery(PICTREE.GET_POINT_TREND, getPictreeDataPointTrend);
}
