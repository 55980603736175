import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  Chip,
} from "@material-ui/core";
import { getMissionRankingData } from "actions";
import MUIDataTable from "mui-datatables";
import * as H from "helper";

// function setChipLabel(label, classes) {
//   const colorKeyName =
//     label.toLowerCase().replace(/[^A-Z0-9]+/gi, "");
//   return <Chip className={classes[colorKeyName]} size="small" label={label} />;
// }

const useStyles = makeStyles((theme) =>
  createStyles({
    volt: {
      paddingTop: theme.spacing(0.5),
      backgroundColor: "#fd5842",
      color: "#fff",
    },
    ampere: {
      paddingTop: theme.spacing(0.3),
      backgroundColor: "#4ba0c1",
      color: "#fff",
    },
    watt: {
      paddingTop: theme.spacing(0.5),
      backgroundColor: "#6eb26e",
      color: "#fff",
    },
    tableHead: {
      height: 48,
      backgroundColor: "#fafafa",
    },
    tableRow: {
      height: 32,
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    tableRowNone: {
      height: 126,
    },
    tblHead: {
      textAlign: 'center',
      fontSize: 14,
      fontWeight: 500,
    },
    tblHeadLeft: {
      textAlign: 'left',
      fontSize: 14,
      fontWeight: 500,
    },
    tblDataCenter: {
      textAlign: 'center',
    },
    tblDataLeft: {
      textAlign: 'left',
    },
    customTitle: {
      fontSize: 16,
      color: "#000",
    },
  })
);

const MissionRanking = ({ missionRankingData, getMissionRankingData }) => {
  const classes = useStyles();

  useEffect(() => {
    getMissionRankingData();
  }, []);

  const columns = [
    {
      name: "順",
      options: {
        filter: false,
        customHeadRender: ({ index, ...column }) => (
          <th key={index} className={classes.tblHead}>順</th>
        ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
    {
      name: "プレイヤーID",
      options: {
        filter: false,
        customHeadRender: ({ index, ...column }) => (
          <th key={index} className={classes.tblHeadLeft}>プレイヤーID</th>
        ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataLeft}>{value}</div>;
        },
      },
    },
    {
      name: "プレイヤー名",
      options: {
        filter: false,
        customHeadRender: ({ index, ...column }) => (
          <th key={index} className={classes.tblHead}>プレイヤー名</th>
        ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
    // {
    //   name: "チーム",
    //   options: {
    //     filter: true,
    //     customHeadRender: ({ index, ...column }) => (
    //       <th key={index} className={classes.tblHead}>チーム</th>
    //     ),
    //     customBodyRender: (value) => {
    //       return <div className={classes.tblDataCenter}>
    //               {/* <span className={`${classes[value.toLowerCase()]}`}>{value}</span> */}
    //               {setChipLabel(value, classes)}
    //              </div>;
    //     },
    //   },
    // },
    {
      name: "ミッション達成数",
      options: {
        filter: false,
        customHeadRender: ({ index, ...column }) => (
          <th key={index} className={classes.tblHead}>達成数</th>
        ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
  ];
  const options = {
    filterType: "checkbox",
    filter: true,
    pagination: true,
    search: true,
    print: false,
    download: true,
    viewColumns: false,
    downloadOptions: {
      filename: "ミッション達成ランキングデータ",
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
    selectableRows: "none",
    rowsPerPage: 10,
    textLabels: {
      body: {
        noMatch: "データを取得中です。。",
        toolTip: "",
      },
      toolbar: {
        search: "検索",
        viewColumns: "列の表示",
        downloadCsv: "CSVダウンロード",
        filterTable: "テーブルのフィルター",
      },
      viewColumns: {
        title: "表示する列",
        titleAria: "テーブルの列の表示/非表示",
      },
      pagination: {
        next: "次のページ",
        previous: "前のページ",
        rowsPerPage: "表示件数",
      },
      filter: {
        all: "全て",
        title: "フィルター",
        reset: "リセット",
      },
      selectedRows: {
        text: "レコードを選択",
        delete: "削除",
        deleteAria: "選択したレコードを削除する",
      },
    },
    rowsPerPageOptions: [10, 20, 50, 100],
  };
  return (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <MUIDataTable
          title={<span className={classes.customTitle}>達成ランキング</span>}
          data={missionRankingData}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
};

MissionRanking.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  missionRankingData: state.pictree.missionRankingData,
});

const mapDispatchToProps = (dispatch) => ({
  getMissionRankingData: (param) => dispatch(getMissionRankingData(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(MissionRanking));
