import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Breadcrumbs,
  Grid,
  Typography,
  Chip,
} from "@material-ui/core";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import HomeStyles from "pages/HomeStyles";
import {
  setCurrentPage,
  getPhotoShootMapDataNtt,
  setPictreeMapType,
} from "actions";
import * as C from "components";
import PhotoShootMapNtt from "components/PicTree/Game/Map/PhotoShootMapNtt";
import SingleSelect from "components/Select/SingleSelect";
import CalendarRangePicTreeNtt from "components/Calendar/CalendarRangePicTreeNtt";

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    breadcrumbs: {
      marginTop: 16,
      minHeight: 30,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
  })
);

const PicTreeMapNtt = ({
  setCurrentPage,
  pageTopLoader,
  photoShootMapData,
  getPhotoShootMapDataNtt,
  setPictreeMapType,
}) => {
  const classes = useStyles();
  const [chartSize, setChartSize] = useState({ name: "撮影ずみ", value: "taken" });

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    getPhotoShootMapDataNtt({type: "denchu", filter: "taken"});
  }, []);

  const handleChange = (event) => {
    setChartSize(event.target);
    setPictreeMapType(event.target.value)
    getPhotoShootMapDataNtt({type: "denchu", filter: event.target.value});
  };

  return (
    <Fragment>
      <C.ContentWrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Breadcrumbs separator="›" className={classes.breadcrumbs}>
              <Typography>PicTrée</Typography>
              <Typography variant="body1">撮影</Typography>
              <Typography variant="body1">地図表示</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={classes.fillRemailSpace}></Grid>
          <Grid item>
            <CalendarRangePicTreeNtt mDate={"2024/12/7"} page="pictree" pictree />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: 16, marginRight: 8, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item style={{ marginTop: 0, marginLeft: 8, }}>
            <SingleSelect
              selected={chartSize}
              options={[
                { name: `撮影済み`, value: "taken" },
                { name: `未撮影`, value: "untaken" },
              ]}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item>
          </Grid>
        </Grid>

        {photoShootMapData.length > 0 &&
          <PhotoShootMapNtt
            records={photoShootMapData}
            type="photoshoot"
            width="100%"
            height="85vh"
            status={chartSize === undefined ? "taken" : chartSize.value}
          />
        }
        {photoShootMapData.length == 0 &&
          <PhotoShootMapNtt
            records={[]}
            type="photoshoot"
            width="100%"
            height="85vh"
            status="taken"
          />
        }
      </C.ContentWrapper>
    </Fragment>
  );
};

PicTreeMapNtt.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  photoShootMapData: state.pictreeNtt.photoShootMapData,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getPhotoShootMapDataNtt: (param) => dispatch(getPhotoShootMapDataNtt(param)),
  setPictreeMapType: (param) => dispatch(setPictreeMapType(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(HomeStyles, { withTheme: true })(PicTreeMapNtt));
