import { put, delay, takeEvery, all, call, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import { setPageTopLoader, setRealTimeMapData } from "actions";
import { getJwtToken, getDataFromRDS, getDataFromSxi } from "api";
import { PICTREE, ENDPOINT } from "../../constants";
import seasonConfig from '../../utils/pictree/SeasonDataConfig';

export const currentPage = (state) => state.page.currentPage;
export const dateRange = (state) => state.page.dateRangePicTree;

const findSeason = (targetDate) => {
  // Convert the targetDate to a moment object for comparison
  const target = moment(targetDate, "YYYY-MM-DD");

  for (let config of seasonConfig.seasonConfig) {
    const startDate = moment(config.dateRange[0], "YYYY-MM-DD");
    const endDate = moment(config.dateRange[1], "YYYY-MM-DD");

    // Check if the target date falls within the date range
    if (target.isBetween(startDate, endDate, null, '[]')) {
      return typeof config.season !== "number" ? config.season : `Season${config.season}`;
    }
  }
  return null; // If no matching season is found
};

const findGameSpaceId = (seasonName) => {
  const config = seasonConfig.gameSpaceConfig.find(item => item.name === seasonName);
  return config ? config.game_space_id : null;
};

export function* getPictreeRealTimeMapData(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const seasonName = findSeason(rangeTarget[1]);
    let tableName = "DenchuPhotoShootDenchuEvtProd";
    const property = action.payload.property;
    let propertyQury = property === "ntt" ? `AND property = 'ntt'` : ``;
    if (property === "tepco" && action.payload.type === "denchu") {
      propertyQury = `AND property = 'tepco'`
    }

    switch (action.payload.type) {
      case "kankiko":
        tableName = "DenchuPhotoShootKankikoEvtProd";
        break;
      case "groundEquipiment":
        tableName = "DenchuPhotoShootGeEvtProd";
        break;
      case "checkin":
        tableName = "DenchuCheckInEvtProd";
        break;
      default:
        tableName = "DenchuPhotoShootDenchuEvtProd";
        break;
    }

    let sqlCountMapData = {
      query: `
        SELECT COUNT(*)
        FROM ${tableName}
        WHERE logDate >= (UNIX_TIMESTAMP(NOW() - INTERVAL ${action.payload.range} MINUTE) * 1000)
        ${propertyQury};`,
      database: "KpiDashboard",
      type: "list",
    };

    const [dataPhotoCount] = yield all([
      call(getDataFromRDS, sqlCountMapData, ENDPOINT.GET_DATA_USERS, jwtToken),
    ]);
    const countPhoto = JSON.parse(dataPhotoCount.body);

    let recordArray = [], eachData = {};
    const LIMIT = 1500;
    let offset = 0;

    while (offset < countPhoto[0][0]["longValue"]) {
      let sqlQueryCountPhotoDenchu = {
        query: `
        SELECT *
        FROM ${tableName}
        WHERE logDate >= (UNIX_TIMESTAMP(NOW() - INTERVAL ${action.payload.range} MINUTE) * 1000)
        ${propertyQury}
        ORDER BY logDate DESC LIMIT ${LIMIT} OFFSET ${offset};`,
        database: "KpiDashboard",
        type: "list",
      };

      const [dataPhotoDenchu] = yield all([
        call(getDataFromRDS, sqlQueryCountPhotoDenchu, ENDPOINT.GET_DATA_USERS, jwtToken),
      ]);
      const countPhotoDenchu = JSON.parse(dataPhotoDenchu.body);
      _.each(countPhotoDenchu, function (data, index) {
        eachData.user = data[2]["stringValue"];
        eachData.asset_id = data[4]["stringValue"];
        eachData.latitude = parseFloat(data[5]["stringValue"]);
        eachData.longitude = parseFloat(data[6]["stringValue"]);
        eachData.parts = data[7]["stringValue"];
        eachData.picCount = data[8]["longValue"];
        eachData.dateTime = moment(data[14]["longValue"]).tz("Asia/Tokyo").format('YYYY/MM/DD hh:mm A');
        recordArray.push(eachData);
        eachData = {};
      });
      offset += LIMIT;
    }

    // get untaken records
    let unTakenRecordArray = [], each = {};
    if (property === "ntt") {
      let sqlQueryCountPhotoDenchu = {
        query: `
          SELECT *
          FROM power_asset AS pa
          INNER JOIN power_asset_detail_of_ntt AS pad
              ON pa.id = pad.power_asset_id
          WHERE pa.id IN (
              SELECT power_asset_id
              FROM power_asset_state
              WHERE game_space_id IN ('cm46zidp10001bpp63r3cfhwx', 'cm46zidp30003bpp6cxq101mo')
              AND power_asset_id NOT IN (
                  SELECT DISTINCT power_asset_id
                  FROM review_request
                  WHERE game_space_id IN ('cm46zidp10001bpp63r3cfhwx', 'cm46zidp30003bpp6cxq101mo')
                  AND status = 'APPROVED'
              )
          );
        `,
        database: "KpiDashboard",
        type: "list",
      };

      const [tempMapUntaken] = yield all([
        call(getDataFromSxi, sqlQueryCountPhotoDenchu, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      ]);

      _.each(tempMapUntaken, function (data, index) {
        each.asset_id = data["power_asset_id"];
        each.latitude = parseFloat(data["latitude"]);
        each.longitude = parseFloat(data["longitude"]);
        each.prefectural_area = data["prefectural_area"];
        each.exchange_office_name = data["exchange_office_name"];
        each.asset_info = data["asset_info"];
        each.photography_target = data["photography_target"];
        unTakenRecordArray.push(each);
        each = {};
      });
    } else {
      // @todo its tepco, get accessing time sesaon id
    }

    yield put(setRealTimeMapData([recordArray, unTakenRecordArray]));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export default function* watchPageSettings() {
  yield takeEvery(PICTREE.GET_REALTIME_MAP_DATA,getPictreeRealTimeMapData);
}
