import { put, call, takeEvery, select, all } from "redux-saga/effects";
import _ from 'lodash';
import moment from 'moment-timezone';
import { getJwtToken, getDataFromRDS, getDataFromSxi, getDataFromKpiDash } from "api";
import { PICTREE, ENDPOINT } from "../../constants";

import {
  setPageTopLoader,
  setSeasonData,
} from 'actions';

export function* handleSeasonData() {
  try {
    const jwtToken = yield call(getJwtToken);
    const sqlQueryGetSeason = {
      query: `
        SELECT gs.id           AS 'seasonId'
            ,sn.official_name  AS 'seasonName'
            ,gt.start_datetime AS 'seasonStart'
            ,gt.end_datetime   AS 'seasonEnd'
        FROM game_space gs
        INNER JOIN game_term gt ON gt.id = gs.game_term_id
        INNER JOIN season s ON gs.game_term_id = s.id
        INNER JOIN season_name sn ON sn.season_id = s.id AND sn.language_code = 'JA'
        ORDER BY gt.start_datetime DESC
      `,
      database: "KpiDashboard",
      type: "list",
    };
    const [dataSeasonDataTemp] = yield all([
        call(getDataFromSxi, sqlQueryGetSeason, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      ]);

    let seasons = [], seasonObj = {};
    _.each(dataSeasonDataTemp, function(s) {
      if (s.seasonId !== "clxvw56es00005soo28mj4jfs" &&
          s.seasonId !== "cm46zidp10001bpp63r3cfhwx" &&
          s.seasonId !== "cm46zidp30003bpp6cxq101mo") {
        seasonObj.seasonId = s.seasonId;
        seasonObj.seasonName = s.seasonName;
        seasonObj.seasonStart = moment(s.seasonStart).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
        seasonObj.seasonEnd = moment(s.seasonEnd).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
        seasons.push(seasonObj);
        seasonObj = {};
      }
    });
    yield put(setSeasonData(seasons));
  } catch (error) {
    console.log(error);
  }
}

export default function* watchMapSettings() {
  yield takeEvery(PICTREE.GET_SEASON_DATA, handleSeasonData);
}
